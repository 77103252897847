import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Col, Divider, Row, message } from 'antd/es'

import { cloneDeep, isEqual } from 'lodash-es'
import debounce from 'lodash/debounce'

import { LogSorter, PageFilter, PageType } from '@cozero/models'

import LogViewsMenu from '@/organisms/LogViewsMenu'
import LogsTable from '@/organisms/LogsTable'

import DateRangePickerFilter from '@/molecules/DateRangePickerFilter'
import FiltersDrawer from '@/molecules/FiltersDrawer'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppContext } from '@/contexts/app'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import useMemoCompare from '@/hooks/useMemoCompare'
import useTerritories from '@/hooks/useTerritories'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useHasLogEntriesQuery } from '@/redux/logs'
import { useGetOrganizationUsersQuery } from '@/redux/organizations'
import { createFilterOptions, createQueryFilter } from '@/utils/filters'

import classes from './Log.module.less'

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}

const DEFAULT_PAGE_SIZE = 15

function LogGroups(): JSX.Element {
  const { t } = useTranslation('common')
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const featuresAllowed = useAppSelector(getFeaturesAllowed)

  const { getSuppliers, suppliers } = useAppContext()
  const [drawerOpened, setDrawerOpened] = useState(false)

  const {
    filters,
    sorters,
    pageNumber: currentPage,
    saveFilters,
    saveSorters,
    savePageNumber: setCurrentPage,
  } = useFiltersContext()
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [locationFilters, setLocationFilters] = useState(
    filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
  )
  const memoedFilters = useMemoCompare<Omit<PageFilter, 'options'>[] | undefined>(
    filters,
    (
      prevFilters: Omit<PageFilter, 'options'>[] | undefined,
      nextFilters: Omit<PageFilter, 'options'>[] | undefined,
    ) => isEqual(prevFilters, nextFilters),
  )
  useMemo(
    (): {
      filters?: Omit<PageFilter, 'options'>[]
    } => ({
      filters:
        memoedFilters?.map((obj) => {
          const copyObj = cloneDeep(obj)
          if (obj.key === 'categoryId') {
            copyObj.key = copyObj?.key?.replace('categoryId', 'log.categoryId')
            return copyObj
          } else {
            return copyObj
          }
        }) ?? [],
    }),
    [memoedFilters, selectedBusinessUnit?.id],
  )

  const numberOfFiltersApplied = useMemo(
    () =>
      locationFilters?.filter((filter) => !filter.type.includes('date')).flatMap((x) => x.value)
        ?.length,
    [locationFilters],
  )
  const [locationSorters, setLocationSorters] = useState(
    sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
  )
  const { territories } = useTerritories()
  const { data: categories } = useGetCategoriesQuery()
  const { data: users } = useGetOrganizationUsersQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit?.id },
  )

  const { isLoading: isLoadingHasLogEntries } = useHasLogEntriesQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit },
  )
  const { loading: logLoading, reset, getCustomers, customers, getTags, tags } = useLogContext()
  const navigate = useNavigate()
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(
    createFilterOptions({
      type: 'location',
      categories,
      users,
      suppliers,
      customers,
      tags,
      t,
      featuresAllowed,
      territories,
      shownOptions: [
        'businessUnits',
        'locations',
        'owners',
        'categories',
        'products',
        'suppliers',
        'customers',
        'tags',
        'territories',
      ],
      page: 'logs',
    }) ?? [],
  )
  const { locationId } = useParams()
  const queryLocation = new URLSearchParams(useLocation().search).get('location') || locationId

  async function onSearch(filters: PageFilter[]): Promise<void> {
    saveFilters(filters)
  }

  async function changeSorting(sorters: LogSorter[]): Promise<void> {
    saveSorters(sorters)
  }

  const openFilters = (): void => {
    const deprecatedView = locationFilters.find(
      (filter) =>
        filter.key !== 'startDate' &&
        filter.key !== 'endDate' &&
        filter.type !== 'dateRange' &&
        filter.selectedCondition?.key !== 'in' &&
        filter.selectedCondition?.key !== 'contains',
    )
    if (!deprecatedView) {
      setDrawerOpened(true)
    } else {
      message.error(t('views.update.failure'))
    }
  }

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'location',
        categories,
        users,
        suppliers,
        customers,
        tags,
        t,
        featuresAllowed,
        territories,
        shownOptions: [
          'businessUnits',
          'locations',
          'owners',
          'categories',
          'products',
          'suppliers',
          'customers',
          'tags',
          'territories',
        ],
        page: 'logs',
      }) ?? [],
    )
  }, [categories, selectedBusinessUnit?.key, suppliers, customers, tags, users])

  useEffect(() => {
    if (queryLocation && currentPage === 1) {
      const queryFilter = createQueryFilter({
        value: [queryLocation],
        type: 'location',
        t,
        options: [],
      })
      saveFilters(queryFilter)
      navigate(location.pathname)
    }
  }, [currentPage, queryLocation, selectedBusinessUnit?.key])

  useEffect(() => {
    setLocationFilters(
      filters?.filter((filter) => filter.logType === 'location' || !filter.logType),
    )
  }, [filters])

  useEffect(() => {
    setLocationSorters(
      sorters?.filter((sorter) => sorter.logType === 'location' || !sorter.logType),
    )
  }, [sorters])

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (selectedBusinessUnit) {
      getSuppliers()
      getCustomers()
      getTags()
    }
  }, [filters, selectedBusinessUnit?.key])

  const isLoading = logLoading || isLoadingHasLogEntries

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col className={classes.logsContainer} span={24}>
              <Row justify="space-between">
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={8}>
                      <LogViewsMenu logType="location" />
                    </Col>
                    <Col span={16} className={classes.filtersWrapper}>
                      <Row gutter={[16, 16]}>
                        <Col>
                          <DateRangePickerFilter
                            saveFilters={onSearch}
                            filters={locationFilters}
                            logType={'location'}
                          />
                        </Col>
                        <Col>
                          <Button
                            onClick={() => openFilters()}
                            category={AnalyticsCategories.LOGS}
                            action={'open-filter'}
                            className={`${classes.buttonRow} ${
                              locationFilters?.filter((filter) => !filter.type.includes('date'))
                                ?.length && classes.activeButton
                            }`}
                            prefixIcon={<HiOutlineFilter />}
                          >
                            <Text
                              size="xl"
                              fontWeight="medium"
                              className={`${classes.buttonTitle} ${
                                locationFilters?.length && classes.activeTitle
                              }`}
                            >
                              {t('log.filter.add')}
                            </Text>
                            {!!numberOfFiltersApplied && (
                              <Tag size="xs" className={classes.filterTag} shape="pill">
                                {`${numberOfFiltersApplied}`}
                              </Tag>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              <LogsTable
                locationId={queryLocation as string}
                type={'location'}
                businessUnitsAllowed={featuresAllowed.includes('business-units') || false}
                cbamAllowed={featuresAllowed.includes('cbam') || false}
                filters={locationFilters}
                sorters={locationSorters}
                pageSize={pageSize}
                setPageSize={setPageSize}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                currentPage={currentPage || 1}
                setCurrentPage={setCurrentPage}
                selectedBusinessUnit={selectedBusinessUnit ?? undefined}
                loading={isLoading}
                setSort={changeSorting}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <FiltersDrawer
        search={debounce(onSearch, 500)}
        pageType={PageType.LOCATION}
        filters={[...locationFilters] as (PageFilter & { key: FilterKey })[]}
        visible={drawerOpened}
        filterOptions={
          [...filterOptions] as (PageFilter & {
            options: {
              key?: string
              value: string
              label: string
            }[]
          })[]
        }
        onClose={() => setDrawerOpened(false)}
        featuresAllowed={featuresAllowed}
      />
    </>
  )
}

export default LogGroups
