import {
  OrganizationCarbonFootprintSearchDto,
  OrganizationCarbonFootprintSearchFiltersDto,
  SearchLogEntryDto,
} from '@cozero/dtos'
import { LogEntriesSearchPage, LogEntriesTableColumnKey, LogEntry, Page } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { PARTIAL_LIST } from '@/redux/types'
import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_LOG_ENTRY, TAG_PAGINATED_LOG_ENTRIES } from './tags'

export type SearchDto = SearchLogEntryDto & {
  filters: Partial<OrganizationCarbonFootprintSearchFiltersDto>
} & {
  selectedBusinessUnitId: number
}

const logEntriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogEntry: builder.query<LogEntry, number | string>({
      query: (id) => ({
        url: logApiGatewayClient.logEntries.GET_ONE.replace(':id', id.toString()),
        method: 'GET',
        params: {
          id,
        },
      }),
      providesTags: (result) => [{ type: TAG_LOG_ENTRY, id: result?.id }],
    }),
    getPaginatedLogEntries: builder.query<
      Page<LogEntry>,
      {
        logIds: number[]
        pageNumber?: number
        pageSize?: number
      }
    >({
      query: (logEntry) => ({
        url: logApiGatewayClient.logEntries.GET_MANY,
        method: 'GET',
        params: logEntry,
      }),
      providesTags: (result) =>
        providesList<LogEntry[], typeof TAG_PAGINATED_LOG_ENTRIES>(
          result?.data,
          TAG_PAGINATED_LOG_ENTRIES,
          PARTIAL_LIST,
        ),
    }),
    getSearchLogEntries: builder.query<
      LogEntriesSearchPage<OrganizationCarbonFootprintSearchDto>,
      SearchDto
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.SEARCH,
        method: 'POST',
        params,
        data: filters,
      }),
      extraOptions: {
        retryCondition: (error, queryArgs, extra) => error.status === 504 && extra.attempt < 3,
      },
    }),
    exportLogEntries: builder.query<
      undefined,
      SearchDto & {
        type: 'csv' | 'xlsx'
        visibleColumns: LogEntriesTableColumnKey[] | undefined
      }
    >({
      query: ({ filters, ...params }) => ({
        url: logApiGatewayClient.logEntries.EXPORT,
        method: 'POST',
        params,
        data: filters,
      }),
    }),
  }),
})

export const {
  useGetPaginatedLogEntriesQuery,
  useGetSearchLogEntriesQuery,
  useLazyExportLogEntriesQuery,
} = logEntriesApiSlice

export default logEntriesApiSlice
