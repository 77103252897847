import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Spin, message } from 'antd'

import { FactorRequest } from '@cozero/models'

import classes from '@/pages/Log/Factors/classes.module.less'

import FactorRequestsTable from '@/organisms/FactorRequestsTable'

import FactorRequestButton from '@/molecules/FactorRequestButton'

import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { useFactorContext } from '@/contexts/factor'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

const FactorRequests = (): ReactElement => {
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState(false)
  const { factorRequests, getFactorRequests, saveFactorRequest } = useFactorContext()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  const fetchData = async (): Promise<void> => {
    if (selectedBusinessUnit) {
      setLoading(true)
      await getFactorRequests(selectedBusinessUnit.id)
      setLoading(false)
    }
  }

  const updateFactorRequest = async (
    id: number,
    factorRequest: Partial<FactorRequest>,
  ): Promise<void> => {
    setLoading(true)
    if (factorRequest.denominatorUnitId) {
      await saveFactorRequest(id, {
        ...factorRequest,
        denominatorUnitId: factorRequest.denominatorUnitId,
        value: parseFloat(`${factorRequest.value}`),
      })
    }

    await fetchData()
    if (factorRequest.status === 'ACCEPTED') {
      message.success(t('factors.accept-success'), 5)
    }
    if (factorRequest.status === 'REJECTED') {
      message.info(t('factors.reject-info'), 5)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [selectedBusinessUnit])

  return (
    <Spin spinning={loading}>
      <div className={classes.innerContainer}>
        <div className={classes.headerRow}>
          <Col xxl={12} xl={12} lg={16} span={24} className={classes.header}>
            <Title size="sm">{t('customer-requests.title')}</Title>
            <Text size="xl" color="secondary">
              {t('customer-requests.subtitle-org')}
            </Text>
          </Col>
          {featuresAllowed.includes('supplier-engagement') && (
            <Col>
              <FactorRequestButton />
            </Col>
          )}
        </div>
        <Row>
          <Col span={24} className={classes.factorsTableContainer}>
            <FactorRequestsTable
              updateFactorRequest={updateFactorRequest}
              factorRequests={factorRequests.sort(
                (f1, f2) =>
                  (f1.status === 'SUBMITTED' ? 0 : f1.value ? 1 : 2) -
                  (f2.status === 'SUBMITTED' ? 0 : f2.value ? 1 : 2),
              )}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default FactorRequests
