import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'

import { ColumnOptions, Datum, Options, Plot } from '@antv/g2plot'
import { ChartPivotRow, SeriesNamesColumn } from '@cubejs-client/core'
import { ColumnChart, ColumnChartProps } from '@opd/g2plot-react'
import { each, flatten, forEach, groupBy, sortBy, sum } from 'lodash'
import moment from 'moment'

import { ReportType } from '@cozero/models'

import { useGetGraphMeasureUnitTitle } from '@/hooks/useGetGraphMeasureUnitTitle'
import useGraphs from '@/hooks/useGraphs'
import { columnStyles, legendStyles, padding, xAxisStyles, yAxisStyles } from '@/styles/graphs'

interface GraphInput {
  x: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  type: string | undefined
}

function formatXLabels(
  item: string,
  formatXLabelsWithEllipses: boolean,
  timeGranularity?: 'month' | 'quarter' | 'year',
): string {
  if (isDate(item)) {
    return `${
      timeGranularity === 'month' || !timeGranularity
        ? moment(item).format('MMM YYYY')
        : timeGranularity === 'quarter'
        ? `Q${moment(item).quarter()} ${moment(item).year()}`
        : moment(item).year()
    }`
  }

  if (formatXLabelsWithEllipses) {
    return formatLabelWithEllipses(item)
  }

  return item.split(' ').join(`\n`).toUpperCase()
}

/**
 * This function checks if the input data is a valid date.
 */
function isDate(value: string): boolean {
  // don't parse single digits as date -> scope 1,2,3 emission labels break
  return !!(moment(value).isValid() && String(value).match(/^\d\d+/))
}

function formatLabelWithEllipses(item: string): string {
  return item.length > 12
    ? item.slice(0, 6) + '...' + item.slice(item.length - 6, item.length)
    : item
}

/**
 * This function reorders the graph input data for presentation in the UI.
 * The outcome is that the order of columns is descending based on the cumulative value of the "blocks" within a column.
 * The "blocks" within a column are sorted alphabetically.
 */
function orderDataByValueThenAlphabetically(data: GraphInput[]): GraphInput[] {
  // The sorting algorigm only makes sense for numeric values
  if (data.some((item) => typeof item.value !== 'number')) {
    return data
  }

  const grouped = groupBy(data, (value) => value.x)

  forEach(grouped, (graphInput, key) =>
    graphInput.sort((a, b) => (a.type ?? '').localeCompare(b.type ?? '')),
  )

  // If the x-axis is a date, sort the columns by date, otherwise sort by cummulative value
  const sorted = each(grouped, (_, key) => isDate(key))
    ? sortBy(grouped, (_, key) => moment(key))
    : sortBy(grouped, (value, _) => sum(value.map((x) => x.value as number))).reverse()

  return flatten(sorted)
}

const Bar = React.memo(
  ({
    chartData,
    height,
    keys,
    visible,
    disableSlider,
    showLegend,
    switchAxis,
    isStack,
    dimensionType,
    timeGranularity,
    chartRef,
    svgChartRef,
    colorFunction,
    formatXLabelsWithEllipses = false,
  }: {
    chartData: ChartPivotRow[]
    height: number | null
    keys: SeriesNamesColumn[]
    visible: boolean
    dimensionType?: string
    disableSlider?: boolean
    showLegend: boolean
    switchAxis: boolean
    isStack: boolean
    timeGranularity?: 'month' | 'quarter' | 'year'
    chartRef?: MutableRefObject<Plot<Options> | null>
    svgChartRef?: MutableRefObject<Plot<Options> | null>
    colorFunction?: (datum: Datum) => string
    formatXLabelsWithEllipses: boolean
  }): ReactElement | null => {
    const { graphStyles, customTooltip } = useGraphs({
      graphType: ReportType.BAR,
      switchAxis,
      dimensionType,
      columns: keys,
    })
    const [isGroup, setIsGroup] = useState(false)
    const [barProps, setBarProps] = useState<ColumnOptions>({ data: [], xField: 'x', yField: 'y' })
    const { getGraphMeasureUnitTitle } = useGetGraphMeasureUnitTitle()

    const config: Omit<ColumnChartProps, 'data' | 'xField'> = {
      appendPadding: padding,
      xAxis: {
        label: {
          formatter: (item) => formatXLabels(item, formatXLabelsWithEllipses, timeGranularity),
          autoHide: true,
          autoRotate: true,
          style: {
            ...xAxisStyles.label?.style,
            fontWeight: 'normal',
          },
          offset: 15,
        },
      },
      yField: 'value',
    }

    const camelCaseToSentence = (text: string): string => {
      const result = text.replace(/([A-Z])/g, ' $1')
      return result.charAt(0).toUpperCase() + result.slice(1)
    }

    useEffect(() => {
      const isGroupAux = isStack ? !isStack : chartData?.[0]?.xValues?.length > 1
      setIsGroup(isGroupAux)
    }, [isStack, chartData])

    useEffect(() => {
      const data: GraphInput[] = chartData.reduce((acc: GraphInput[], curr) => {
        let tmp = [
          {
            x: curr.xValues?.at(0),
            value: curr[keys[0].yValues.at(-1) ?? ''],
            type: curr.xValues?.at(-1) || curr.xValues?.at(0),
          },
        ]
        if (keys.length > 1 && Object.keys(curr).length > 3) {
          tmp = keys.map((key) => ({
            x: curr.xValues?.at(0),
            value: curr[key.yValues[0]],
            type: camelCaseToSentence(key.key.split('.').at(-1) as string),
          }))
        }
        return [...acc, ...tmp]
      }, [])

      const orderedData = orderDataByValueThenAlphabetically(data)

      const barPropsAux: ColumnOptions = {
        ...graphStyles,
        ...config,
        height: height as number,
        legend: showLegend
          ? {
              ...legendStyles,
              itemName: {
                style: legendStyles.itemName?.style,
                formatter: (name) =>
                  moment(name, moment.ISO_8601).isValid()
                    ? `${
                        timeGranularity === 'month' || !timeGranularity
                          ? moment(name).format('MMM YYYY')
                          : timeGranularity === 'quarter'
                          ? `Q${moment(name).quarter()} ${moment(name).year()}`
                          : moment(name).year()
                      }`
                    : name,
              },
            }
          : false,
        isStack,
        yAxis: {
          field: 'value',
          ...yAxisStyles,
          title: {
            style: yAxisStyles.title?.style,
            text: getGraphMeasureUnitTitle(keys[0].key),
          },
        },
        columnStyle: isStack ? columnStyles : { radius: columnStyles.radius },
        isGroup,
        maxColumnWidth: 60,
        seriesField: isGroup ? (switchAxis ? 'x' : 'type') : 'type',
        xField: switchAxis ? 'type' : 'x',
        data: orderedData,
        tooltip: {
          ...customTooltip,
          formatter: (item) => {
            let x = item.x
            let name = item.type || item.x
            if (switchAxis) {
              x = item.type
              name = item.x
            }
            return {
              name: `${moment(name, moment.ISO_8601, true).isValid() ? moment(name).year() : name}`,
              value: item.value,
              title: `${moment(x, moment.ISO_8601, true).isValid() ? moment(x).year() : x}`,
            }
          },
        },
        brush: {
          enabled: disableSlider,
          action: 'filter',
        },
        ...(colorFunction && { color: colorFunction }), // overwriting value with undefined kills the default theme
      }
      setBarProps(barPropsAux)
    }, [
      chartData,
      isStack,
      disableSlider,
      switchAxis,
      keys,
      showLegend,
      dimensionType,
      graphStyles,
    ])

    if (!height) {
      return null
    }

    return (
      <>
        <ColumnChart
          height={height}
          {...barProps}
          renderer={'canvas'}
          style={{ display: visible ? 'block' : 'none' }}
          chartRef={(ref) => {
            if (ref && chartRef) {
              chartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
        <ColumnChart
          height={height}
          {...barProps}
          style={{ display: 'none' }}
          renderer={'svg'}
          width={1000}
          chartRef={(ref) => {
            if (ref && svgChartRef) {
              svgChartRef.current = ref as unknown as Plot<Options>
            }
          }}
        />
      </>
    )
  },
)
Bar.displayName = 'Bar'

export default Bar
