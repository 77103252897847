import React, { ReactElement, memo, useMemo } from 'react'

import classNames from 'classnames'

import classes from './Dot.module.less'

export type DotColor = 'default' | 'info' | 'success' | 'danger' | 'scope1' | 'scope2' | 'scope3'

type Props = (
  | {
      type: DotColor
      color?: never
    }
  | {
      type?: never
      color: string
    }
) & { className?: string }

const Dot = ({ type = 'default', color, className }: Props): ReactElement => (
  <div
    className={classNames(classes.dot, classes[type], className)}
    style={{ backgroundColor: color }}
  />
)

export default memo(Dot)
