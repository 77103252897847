import React, { ReactElement, memo, useEffect } from 'react'

import Select from '@/atoms/Select'
import { SelectOptionsProperties } from '@/atoms/Select'

import { isJson } from '@/utils/log'

import classes from './classes.module.less'

const UserOptionSelect = ({
  onChange,
  value,
  options,
  initialValue,
  className,
}: {
  className?: string
  value?: number | string
  onChange: (arg: number | string) => void
  options: SelectOptionsProperties[]
  initialValue: number | string
}): ReactElement => {
  useEffect(() => {
    if (
      options.length &&
      !options.find(
        (option) =>
          option.value === initialValue ||
          (typeof option.value === 'string' &&
            isJson(option.value) &&
            JSON.parse(option.value).includes(initialValue)),
      )
    ) {
      const value = options[0].value
      onChange(value !== undefined && !isNaN(Number(value)) ? Number(value) : value)
    }
  }, [initialValue])

  useEffect(() => {
    onChange(
      initialValue !== undefined && !isNaN(Number(initialValue))
        ? Number(initialValue)
        : initialValue,
    )
  }, [])

  return (
    <Select
      className={className}
      style={{ marginLeft: '1%' }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      dropdownClassName={classes.select}
      size="large"
      options={options}
      value={value}
      defaultValue={value ?? initialValue}
      data-cy="unit-selection-input"
    />
  )
}

export default memo(UserOptionSelect)
