import React, { Dispatch, SetStateAction, useContext } from 'react'

import {
  ActivityDataSource,
  BusinessUnit,
  EmissionFactorChangelog,
  EmissionFactorWithIncludes,
  FactorOrigin,
  FactorRequest,
  FactorRequestResponse,
  Location,
  Page,
  PageFilter,
  Product,
  Territory,
  Unit,
  User,
} from '@cozero/models'

import { ParsedFormValues } from '@/organisms/FactorRequestForm/types'
import { FormValues } from '@/organisms/FactorRequestResponseForm/types'

import useFactorHandler from '../hooks/useFactor'

export interface FactorContextInterface {
  createCustomFactor: (
    factorId: number,
    body: Partial<EmissionFactorWithIncludes>,
  ) => Promise<EmissionFactorWithIncludes | void>
  updateCustomFactor: (
    factorId: number,
    body: Partial<EmissionFactorWithIncludes>,
  ) => Promise<EmissionFactorWithIncludes | void>
  deleteCustomFactor: (factorId: number) => Promise<void>
  getFactors: (query: {
    logId?: number
    subcategoryId?: number
    activityDataSourceId?: number
    used?: boolean
    includeDeleted?: boolean
    custom?: true
    type?: 'product' | 'location'
    logEntryId?: number
    page: number
    pageSize: number
    filters?: PageFilter[]
  }) => Promise<Page<EmissionFactorWithIncludes> | void>
  getFactorsData: () => Promise<{
    products: Product[]
    locations: Location[]
    businessUnits: BusinessUnit[]
  }>
  createFactorRequest: (
    factorRequest: ParsedFormValues,
    businessUnitId: number,
  ) => Promise<FactorRequest & { responsibleUser: User }>
  createFactorRequestResponse: (factorRequestResponse: FormValues) => Promise<FactorRequestResponse>
  getFactorRequests: (businessUnitId: number) => Promise<FactorRequest[]>
  getFactorRequest: (id: number) => Promise<FactorRequest>
  getFactorRequestsAsSupplier: () => Promise<FactorRequest[]>
  factorRequestsAsSupplier: FactorRequest[]
  factorRequests: FactorRequest[]
  setFactorRequest: Dispatch<SetStateAction<FactorRequest | null | undefined>>
  factorRequest: FactorRequest | null | undefined
  saveFactorRequest: (
    id: number,
    factorRequest: { denominatorUnitId: number } & Partial<FactorRequest>,
  ) => Promise<FactorRequest | void>
  applyFactorChanges: (emissionFactorsToUpdate: Partial<EmissionFactorChangelog>[]) => Promise<void>
  getFactorChangelogs: (
    page: number,
    pageSize: number,
  ) => Promise<{ docs: EmissionFactorChangelog[]; totalRecords: number }>
  getFactorFilters: () => Promise<{
    factorOrigins: FactorOrigin[]
    units: Unit[]
    activityDataSources: ActivityDataSource[]
    territories: Territory[]
  }>
}

export const factorContext = React.createContext<FactorContextInterface | undefined>(undefined)

const { Provider } = factorContext

export function useFactorContext(): FactorContextInterface {
  const contextValue = useContext(factorContext)
  if (contextValue === undefined) {
    throw new Error('Context must be inside a Provider')
  }
  return contextValue
}

interface ProviderProps {
  children: React.ReactNode
}

const FactorProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const factorAcessors = useFactorHandler()
  return <Provider value={factorAcessors}>{children}</Provider>
}

export default FactorProvider
