import React, { ReactElement, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineCreditCard } from 'react-icons/hi'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Col, Row } from 'antd/es'

import moment from 'moment'

import { routes } from '@cozero/utils'

import { AppErrorFallback } from '@/templates/ErrorFallback/AppErrorFallback'
import SuspenseSpinner from '@/templates/SuspenseSpinner'

import { NavSidebar } from '@/organisms/NavSidebar/NavSidebar'
import { UpgradeModal } from '@/organisms/UpgradeModal/UpgradeModal'

import { Breadcrumbs } from '@/molecules/Breadcrumbs'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Container from '@/atoms/Container'

import { useSubscriptionContext } from '@/contexts/subscription'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useScrollToTop } from '@/hooks/useScrollToTop'
import { useAppSelector } from '@/redux'
import { getIsManagerOrAdmin, selectUser, selectUserOrganization } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import NavOnboardingSupplierSidebar from '../../organisms/NavOnboardingSupplierSidebar'
import ErrorBoundary from '../ErrorBoundary'

import classes from './styles.module.less'

const PageLayout = (): ReactElement => {
  const { t } = useTranslation()
  const { trackPageView } = useMixpanel()
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const organization = useAppSelector(selectUserOrganization)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const useBreadCrumbs =
    location.pathname !== routes.onboardingSupplierStep1 &&
    location.pathname !== routes.onboardingSupplierStep2 &&
    location.pathname !== routes.expiredToken

  const { scrollElementRef } = useScrollToTop()

  const {
    getCalendarLink,
    setSubscribeModalSettings,
    subscribeModalSettings,
    subscriptionExpired,
  } = useSubscriptionContext()
  const trialPeriodDays = useMemo(() => {
    return moment(organization?.createdAt)
      .clone()
      .add(organization?.pricing?.daysOfTrialPeriod, 'days')
      .diff(moment(), 'days')
  }, [organization])
  const [bgColor, setBgColor] = useState('')

  const closeSubscribeModal = (): void => {
    setSubscribeModalSettings({ ...subscribeModalSettings, visible: false })
  }

  const goToSubscriptionPage = (): void => {
    closeSubscribeModal()
    return navigate(routes.settings.subscription)
  }

  useEffect(() => {
    if (
      location.pathname === routes.onboarding ||
      location.pathname === routes.onboardingSupplierStep1 ||
      location.pathname === routes.onboardingSupplierStep2
    ) {
      setBgColor('onboarding-blue')
    } else {
      setBgColor('')
    }

    trackPageView({ url: window.location.href })
  }, [location.pathname])

  useEffect(() => {
    if (subscriptionExpired && location.pathname !== routes.settings.plans) {
      return goToSubscriptionPage()
    }
  }, [subscriptionExpired, location.pathname])

  const TrialNotification = useMemo((): ReactElement | null => {
    if (organization?.pricing?.type !== 'free') {
      return null
    }
    return (
      <div className={classes.notification}>
        <span className={classes.title}>{t('layout.upgrade-cta.title')}</span>
        {trialPeriodDays > 0 ? (
          <span className={classes.subtitle}>
            {trialPeriodDays} {t('layout.days')}
          </span>
        ) : (
          <span className={classes.subtitle}>{t('layout.subscription-expired')}</span>
        )}
        {isManagerOrAdmin && <a href="mailto:support@cozero.io"> {t('contact')}</a>}
      </div>
    )
  }, [subscriptionExpired])

  useEffect(() => {
    if (subscriptionExpired && location.pathname !== routes.settings.plans) {
      return goToSubscriptionPage()
    }
  }, [subscriptionExpired, location.pathname])

  if (!user && localStorage.getItem('EXPIRED_TOKEN') !== 'true') {
    return <Navigate to={routes.logout} />
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={AppErrorFallback}>
        <section className={classes.mainLayout} data-cy="main-layout">
          {location.pathname === routes.onboardingSupplierStep1 ||
          location.pathname === routes.expiredToken ? (
            <NavOnboardingSupplierSidebar
              hideUserMenu={location.pathname === routes.expiredToken}
            />
          ) : (
            <NavSidebar
              trialNotification={TrialNotification}
              supplierOnboarding={user?.role?.type === 'supplier'}
            />
          )}

          <ErrorBoundary FallbackComponent={AppErrorFallback}>
            <main
              ref={scrollElementRef}
              data-cy="content"
              className={`${classes.content} ${
                bgColor === 'onboarding-blue' && classes.onboardingBlue
              } ${location.pathname.split('/').includes('log') && classes.logPage}`}
            >
              {selectedBusinessUnit && !selectedBusinessUnit.active && (
                <Alert type="danger" message={t('layout.inactive-business-unit')} />
              )}
              {useBreadCrumbs ? <Breadcrumbs /> : ''}
              <Row justify="center" className={classes.innerContent}>
                <Container>
                  <Col span={24}>
                    <Suspense fallback={<SuspenseSpinner />}>
                      <Outlet />
                    </Suspense>
                  </Col>
                </Container>
              </Row>
            </main>
          </ErrorBoundary>
        </section>
      </ErrorBoundary>
      <UpgradeModal
        {...subscribeModalSettings}
        handleCancel={closeSubscribeModal}
        getCalendarLink={getCalendarLink}
        goToSubscriptionPage={goToSubscriptionPage}
        organization={organization}
      />
    </>
  )
}

export default PageLayout
