import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter } from 'react-icons/hi'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import { Col, Row } from 'antd/es'

import debounce from 'lodash/debounce'

import {
  ActivityDataSource,
  EmissionFactor,
  EmissionFactorWithIncludes,
  FactorOrigin,
  PageFilter,
  PageType,
  Territory,
  Unit,
} from '@cozero/models'

import classes from '@/pages/Log/Factors/classes.module.less'

import CustomFactorModal from '@/organisms/CustomFactorModal'
import EmissionFactorsDataTable from '@/organisms/EmissionFactorsDataTable'

import FactorRequestButton from '@/molecules/FactorRequestButton'
import FiltersDrawer, { FilterKey } from '@/molecules/FiltersDrawer'

import Button from '@/atoms/Button'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFactorFilterContext } from '@/contexts/factorFilter'
import { useFiltersContext } from '@/contexts/filters'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { createFilterOptions } from '@/utils/filters'

const DEFAULT_PAGE_SIZE = 15

const EmissionFactors = (): ReactElement => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedFactor, setSelectedFactor] = useState<EmissionFactorWithIncludes | undefined>()
  const [filterData, setFilterData] = useState<{
    factorOrigins: FactorOrigin[]
    units: Unit[]
    activityDataSources: ActivityDataSource[]
    territories: Territory[]
  }>({ factorOrigins: [], units: [], activityDataSources: [], territories: [] })
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const { filters: filtersFromUrl } = useFiltersContext()
  const { t } = useTranslation('common')
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  // In createFilterOptions whenever the feature is allowed it adds it as a filter.
  // However EmissionFactors dont seem to have this so it breaks the BE.
  // I do not know why we default filters based on features allowed, so I removed it.
  const index = featuresAllowed.indexOf('business-units', 0)
  if (index > -1) {
    featuresAllowed.splice(index, 1)
  }
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(() =>
    createFilterOptions({
      type: 'factors',
      t,
      featuresAllowed,
      shownOptions: ['factorOrigins', 'units', 'activityDataSources', 'territories'],
      page: 'factors',
      activityDataSources: filterData.activityDataSources,
      factorOrigins: filterData.factorOrigins,
      territories: filterData.territories,
      units: filterData.units,
    }),
  )

  const {
    getFactorFilters,
    updateCustomFactor,
    createCustomFactor,
    getFactors,
    getFactorRequests,
    pageNumber: currentPage,
    savePageNumber: setCurrentPage,
    filters,
    sorters,
    saveFilters,
  } = useFactorFilterContext()

  const [emissionFactorSorters, setEmissionFactorSorters] = useState(sorters)

  const [emissionFactorFilters, setEmissionFactorFilters] = useState(filters)

  const [triggerUpdateTimestamp, setTriggerUpdateTimestamp] = useState<number>()

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'factors',
        t,
        featuresAllowed,
        shownOptions: ['factorOrigins', 'units', 'activityDataSources', 'territories'],
        page: 'factors',
        activityDataSources: filterData.activityDataSources,
        factorOrigins: filterData.factorOrigins,
        territories: filterData.territories,
        units: filterData.units,
      }),
    )
  }, [filterData])

  useEffect(() => {
    if (filtersFromUrl && filtersFromUrl.length) {
      setEmissionFactorFilters(filtersFromUrl)
    } else {
      setEmissionFactorFilters(filters)
    }
  }, [filters, filtersFromUrl])

  const childRef = useRef({
    onRefreshTable: () => undefined,
  })

  const onCloseModal = (): void => {
    setModalIsOpen(false)
  }

  async function onSearch(newFilters: PageFilter[]): Promise<void> {
    saveFilters(newFilters)
  }

  const openDrawer = async (): Promise<void> => {
    getFactorFilters().then(setFilterData)
    setFilterDrawerOpen(true)
  }

  const onSelectFactor = (factor: EmissionFactorWithIncludes): void => {
    setModalIsOpen(true)
    setSelectedFactor(factor)
  }

  async function updateFactor(
    id: number,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    await updateCustomFactor(id, values)
    await fetchData()
    onCloseModal()
  }

  async function createFactor(
    factor: EmissionFactor,
    values: Partial<EmissionFactorWithIncludes>,
  ): Promise<void> {
    await createCustomFactor(factor.id, {
      ...values,
      activityDataSourceId: factor.activityDataSourceId,
      denominatorUnitId: factor.denominatorUnitId,
      unitId: factor.unitId,
      territoryId: factor.territoryId,
      originId: factor.originId,
      value: parseFloat(`${values.value}`),
    })
    await fetchData()
    onCloseModal()
  }

  async function fetchData(): Promise<void> {
    setTriggerUpdateTimestamp(new Date().getTime())

    await getFactors({
      activityDataSourceId: 1,
      used: true,
      custom: true,
      logEntryId: undefined,
      type: undefined,
      page: currentPage,
      pageSize: pageSize,
      filters: filters,
      includeDeleted: false,
    })

    if (selectedBusinessUnit) {
      await getFactorRequests(selectedBusinessUnit.id)
    }
  }

  return (
    <div>
      <div className={classes.innerContainer}>
        <Row className={classes.headerRow}>
          <Col span={24}>
            <Title size="sm">{t('settings.factors.title')}</Title>
          </Col>
          <Col xxl={12} xl={12} lg={16} span={24} className={classes.header}>
            <Text size="xl" color="secondary">
              {t('settings.factors.subtitle')}
            </Text>
          </Col>
        </Row>

        {featuresAllowed.includes('custom-factors') &&
          featuresAllowed.includes('supplier-engagement') && (
            <Row justify="end">
              <FactorRequestButton />
            </Row>
          )}
        <Row>
          <Col span={24} className={classes.factorsTableContainer}>
            <Row justify="end" className={classes.filterButton}>
              <Col>
                <Button
                  onClick={openDrawer}
                  category={AnalyticsCategories.FACTORS}
                  action={'open-filter'}
                  prefixIcon={<HiOutlineFilter />}
                >
                  <Text size="xl" fontWeight="medium">
                    {t('log.filter.add')}
                  </Text>
                </Button>{' '}
              </Col>
            </Row>

            <EmissionFactorsDataTable
              childRef={childRef}
              featuresAllowed={featuresAllowed as string[]}
              currentPage={currentPage || 1}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              setSelectedFactor={onSelectFactor}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              filters={emissionFactorFilters}
              sorters={emissionFactorSorters}
              used={true}
              custom={false}
              activityDataSourceId={''}
              setSort={debounce(setEmissionFactorSorters, 800)}
              triggerUpdateTimestamp={triggerUpdateTimestamp}
            />
          </Col>
        </Row>
      </div>
      <FiltersDrawer
        filters={[...emissionFactorFilters] as (PageFilter & { key: FilterKey })[]}
        visible={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        search={debounce(onSearch, 500)}
        pageType={PageType.FACTOR}
        filterOptions={
          [...(filterOptions ?? [])] as (PageFilter & {
            options: {
              key?: string
              value: string
              label: string
            }[]
          })[]
        }
      />
      <CustomFactorModal
        isVisible={modalIsOpen}
        onClose={onCloseModal}
        selectedFactor={selectedFactor}
        updateCustomFactor={updateFactor}
        createCustomFactor={createFactor}
      />
    </div>
  )
}

export default EmissionFactors
