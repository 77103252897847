import { LogEntryTag } from '@cozero/models'
import { logApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_LOG_ENTRY_TAGS } from './tags'

const logEntryTagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogEntryTags: builder.query<LogEntryTag[], { selectedBusinessUnitId: number }>({
      query: ({ selectedBusinessUnitId }) => ({
        url: logApiGatewayClient.tags.GET_MANY,
        method: 'GET',
        params: {
          businessUnitId: selectedBusinessUnitId,
        },
      }),
      providesTags: (result) =>
        providesList<LogEntryTag[], typeof TAG_LOG_ENTRY_TAGS>(result, TAG_LOG_ENTRY_TAGS),
    }),
  }),
})

export const { useGetLogEntryTagsQuery } = logEntryTagsApiSlice

export default logEntryTagsApiSlice
