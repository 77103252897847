import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle, HiTrash } from 'react-icons/hi'

import { Col, Row, Skeleton, Spin, message } from 'antd/es'

import { debounce } from 'lodash-es'

import {
  ActivityDataSource,
  CalculationMethodWithIncludes,
  Category,
  ClientUserOption,
  Input,
  PreviewCalculation,
  Product,
  ProductLogEntry,
  Subcategory,
  Supplier,
} from '@cozero/models'

import SuppliersModal from '@/organisms/SuppliersModal'
import UserOptionInput from '@/organisms/UserOptionInput'

import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import Label from '@/atoms/Label'
import Pill from '@/atoms/Pill'
import { SelectOptionsProperties } from '@/atoms/Select/Select'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useLifecycleContext } from '@/contexts/lifecycle'
import useAppContext from '@/hooks/useApp'
import useCategories from '@/hooks/useCategories'
import useLog from '@/hooks/useLog'
import useTerritories from '@/hooks/useTerritories'
import { useAppSelector } from '@/redux'
import { getFeaturesAllowed } from '@/redux/auth'
import { useGetCalculationMethodsQuery } from '@/redux/calculationMethods'
import { GRAY_400 } from '@/styles/variables'
import { convertTonnesToGrams, formatNumber } from '@/utils/number'

import { Field, GenericFieldKey, LifecycleStepsID, LifecycleStepsKey } from '../hooks/steps'

import classes from './classes.module.less'

type CascaderNode = {
  value: number
  label: string
  children: CascaderNode[]
}

interface Props {
  fields: Field[]
  deleteEntry: (index: number) => void
  index: number
  childRef?: React.RefObject<{
    resetFields: () => void
  }>
  product: Product
  initialData?: {
    id?: number
    subcategoryId?: number
    calculationMethodId?: number
    categoryId?: number
    activityDataSourceId?: number
    supplierId?: number
    territoryId?: number
    title?: string
    inputs?: { inputKey: string; value: number; unitId: number }[]
  }
  productlifecycleStep: LifecycleStepsKey
}

type OptionsData<T> = T & {
  id: number
  name: string
}

export interface CreateProductLogEntry {
  [GenericFieldKey.TITLE]: string
  [GenericFieldKey.LOG_CATEGORY]: number
  [GenericFieldKey.LOG_SUBCATEGORY]: number
  [GenericFieldKey.CALCULATION_METHOD]: number
  [GenericFieldKey.ACTIVITY_DATA_SOURCE]: number[]
  [GenericFieldKey.SUPPLIER]?: string
  [GenericFieldKey.TERRITORY]?: number
  [GenericFieldKey.INPUTS]?: { inputKey: string; value: number; unitId: number }[]
}

interface ExtraProps {
  onChange?: () => void
  disabled?: boolean
  onClick?: () => void
  options?: SelectOptionsProperties[] | ActivityDataSource[]
  defaultValue?: number | string
  className?: string
}

const GenericStep = ({
  fields: initialFields,
  index,
  deleteEntry,
  childRef,
  product,
  initialData: initialDataFromProps,
  productlifecycleStep,
}: Props): JSX.Element => {
  const NO_DISABLE_KEYS = [GenericFieldKey.TERRITORY, GenericFieldKey.SUPPLIER]
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [fields, setFields] = useState<Field[]>(initialFields)
  const [activeFields, setActiveFields] = useState<{ [key: string]: boolean }>()
  const [categoryOptions, setCategoryOptions] = useState<SelectOptionsProperties[]>()
  const subcategoryOptions = useRef<SelectOptionsProperties[]>()
  const [territoriesOptions, setTerritoriesOptions] = useState<CascaderNode[]>()
  const [suppliersOptions, setSuppliersOptions] = useState<SelectOptionsProperties[]>()
  const [activityDataSourceOptions, setActivityDataSourceOptions] = useState<ActivityDataSource[]>(
    [],
  )
  const [supplierModalVisible, setSupplierModalVisible] = useState<boolean>(false)
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)
  const [carbonValue, setCarbonValue] = useState<number | undefined>()
  const [, setStepName] = useState<string | undefined>()
  const [calculationMethodId, setCalculationMethodId] = useState<number | undefined>()
  const [activityDataSourceId, setActivityDataSourceId] = useState<number | undefined>()
  const [userOptions, setUserOptions] = useState<ClientUserOption[]>([])
  const [initialData, setInitialData] = useState<Props['initialData']>(initialDataFromProps)

  const currentLogEntryId = useRef<number | undefined>(initialDataFromProps?.id)
  const inputs = useRef<
    { inputKey: string; value: number; values?: string; unitId: number; address?: string }[]
  >([])
  const performingMutation = useRef<boolean>(false)

  const { categories, subcategories, setCategoryId, subcategoryId, setSubcategoryId, mutate } =
    useCategories(productlifecycleStep, LifecycleStepsID[productlifecycleStep])
  const { territories } = useTerritories()
  const { organizationSuppliers, getOrganizationSuppliers, createSupplier } = useAppContext()
  const { getActivityDataSources: getActivityDataSourcesFromApi, getUserOptionsForLogCreation } =
    useLog()
  const { setStepData, currentStep, stepsToDisplay } = useLifecycleContext()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const {
    createManyProductLogEntries,
    updateProductLogEntry,
    deleteProductLogEntry,
    loading,
    previewProductLogEntryCalculations,
  } = useLog()
  const { data: calculationMethods, isFetching: isFetchingCalculationMethods } =
    useGetCalculationMethodsQuery({
      subcategoryId,
      productLifecycleStepKey: productlifecycleStep,
    })

  const previousLogEntry = useRef<ProductLogEntry>()

  const getPreviewCalculations = async (logEntryId: number): Promise<void> => {
    setLoadingPreview(true)
    const calculations = (await previewProductLogEntryCalculations(
      logEntryId,
    )) as PreviewCalculation[]
    setLoadingPreview(false)
    setCarbonValue(
      calculations && calculations[0] && calculations[0].carbonFootprint
        ? calculations[0].carbonFootprint
        : 0,
    )
  }

  const setTerrritoryFieldValue = (territoryId: number | null): void => {
    const continentId = territories?.find((x) => x.id === territoryId)?.continentId
    const value = territoryId
      ? continentId
        ? [continentId, territoryId]
        : [territoryId]
      : undefined
    form.setFieldValue(GenericFieldKey.TERRITORY, value)
  }

  useEffect(() => {
    setInitialData(initialDataFromProps)
  }, [initialDataFromProps])

  useEffect(() => {
    if (!initialData) {
      return
    }
    currentLogEntryId.current = initialData?.id
    if (initialData?.title) {
      setName(initialData?.title)
      form.setFieldValue(GenericFieldKey.TITLE, initialData?.title)
      updateActiveFields()
    }

    if (initialData?.categoryId) {
      setCategoryId(initialData?.categoryId)
      form.setFieldValue(GenericFieldKey.LOG_CATEGORY, initialData?.categoryId)
      updateActiveFields()
    }

    if (initialData?.subcategoryId) {
      setSubcategoryId(initialData?.subcategoryId)
      form.setFieldValue(GenericFieldKey.LOG_SUBCATEGORY, initialData?.subcategoryId)
      updateActiveFields()
    }

    if (initialData?.calculationMethodId) {
      setCalculationMethodId(initialData?.calculationMethodId)
      form.setFieldValue(GenericFieldKey.CALCULATION_METHOD, initialData?.calculationMethodId)
      updateActiveFields()
    }

    if (initialData?.activityDataSourceId) {
      setActivityDataSourceId(initialData?.activityDataSourceId)
      form.setFieldValue(GenericFieldKey.ACTIVITY_DATA_SOURCE, [initialData?.activityDataSourceId])
      updateActiveFields()
    }

    if (initialData?.inputs && initialData?.inputs?.length) {
      initialData?.inputs.forEach((input) => form.setFieldValue(GenericFieldKey.INPUTS, input))
      inputs.current = [...initialData.inputs]
      updateActiveFields()
    }

    if (initialData?.territoryId) {
      updateActiveFields()
      setTerrritoryFieldValue(initialData?.territoryId)
    }

    if (initialData?.supplierId) {
      updateActiveFields()
      form.setFieldValue(GenericFieldKey.SUPPLIER, initialData?.supplierId)
    } else {
      form.setFieldValue(GenericFieldKey.SUPPLIER, 'default')
    }

    if (initialData?.id) {
      getPreviewCalculations(initialData.id)
    }
  }, [initialData])

  useEffect(() => {
    mutate()
  }, [productlifecycleStep])

  useImperativeHandle(childRef, () => ({
    resetFields() {
      setName('')
      form.resetFields()
      resetInputs()
      currentLogEntryId.current = undefined
    },
  }))

  const buildOptionsArray = <T,>(
    data: OptionsData<T>[],
  ): SelectOptionsProperties[] | ActivityDataSource[] =>
    data.map(({ id: value, name: label }) => ({ label, value }))

  const calculationMethodOptions = useMemo(
    () =>
      buildOptionsArray<CalculationMethodWithIncludes>(
        calculationMethods ?? [],
      ) as SelectOptionsProperties[],
    [calculationMethods],
  )

  const optionsSelector = useCallback(
    (option: string): SelectOptionsProperties[] | ActivityDataSource[] | undefined => {
      switch (option) {
        case GenericFieldKey.LOG_CATEGORY:
          return categoryOptions
        case GenericFieldKey.LOG_SUBCATEGORY:
          return subcategoryOptions.current as SelectOptionsProperties[]
        case GenericFieldKey.CALCULATION_METHOD:
          return calculationMethodOptions
        case GenericFieldKey.ACTIVITY_DATA_SOURCE:
          return activityDataSourceOptions
        case GenericFieldKey.TERRITORY:
          return territoriesOptions
        case GenericFieldKey.SUPPLIER:
          return suppliersOptions
        default:
          return []
      }
    },
    [
      categoryOptions,
      subcategoryOptions.current,
      calculationMethodOptions,
      activityDataSourceOptions,
      territoriesOptions,
      suppliersOptions,
    ],
  )

  const addExtraProps = useCallback(
    (component: JSX.Element, key: string, extraProps: ExtraProps): JSX.Element => {
      let extraPropsInternal = { ...extraProps }
      if (key !== 'inputs') {
        const options =
          key === GenericFieldKey.LOG_CATEGORY ||
          key === GenericFieldKey.LOG_SUBCATEGORY ||
          key === GenericFieldKey.ACTIVITY_DATA_SOURCE ||
          key === GenericFieldKey.CALCULATION_METHOD ||
          key === GenericFieldKey.TERRITORY ||
          key === GenericFieldKey.SUPPLIER
            ? optionsSelector(key)
            : undefined

        if (key === GenericFieldKey.SUPPLIER) {
          extraPropsInternal = {
            ...extraPropsInternal,
            defaultValue: 'default',
          }
        }
        if (options) {
          extraPropsInternal = { ...extraPropsInternal, options }
        }
      }
      return <component.type {...component.props} {...extraPropsInternal} />
    },
    [optionsSelector],
  )

  const updateActiveFields = (): void => {
    let flag = true
    const updatedActiveFields = fields.reduce((acc, current) => {
      const value = form.getFieldValue(current.key)
      const result = { ...acc, [current.key]: flag }
      if (!value) {
        flag = false
      }
      return result
    }, {})
    setActiveFields({ ...updatedActiveFields })
  }

  const upsertLogEntry = async (): Promise<void> => {
    if (performingMutation.current) {
      return
    }

    let values = {
      ...form.getFieldsValue(),
      inputs: inputs.current,
    }
    let hasChanged = false
    if (previousLogEntry.current) {
      const previousInputs = previousLogEntry.current?.inputs?.map((x: Input) => ({
        inputKey: x.inputKey,
        unitId: x.unitId,
        value: x.value,
        address: x.address,
      }))
      hasChanged =
        JSON.stringify(inputs.current) !== JSON.stringify(previousInputs) ||
        previousLogEntry.current?.title !== values.title ||
        previousLogEntry.current?.territoryId !== values.territoryId?.at(-1) ||
        previousLogEntry.current?.activityDataSourceId !== values.activityDataSourceId?.at(-1) ||
        previousLogEntry.current?.calculationMethodId !== values.calculationMethodId ||
        (previousLogEntry.current?.subcategory?.categoryId !== values.categoryId &&
          values.categoryId) ||
        (previousLogEntry.current?.subcategoryId !== values.subcategoryId &&
          values.subcategoryId) ||
        ((values.supplierId !== 'default' || !!previousLogEntry.current?.supplierId) &&
          previousLogEntry.current?.supplierId !== values.supplierId)
    } else {
      hasChanged =
        JSON.stringify(inputs.current) !== JSON.stringify(initialData?.inputs) ||
        initialData?.title !== values.title ||
        initialData?.territoryId !== values.territoryId?.at(-1) ||
        initialData?.activityDataSourceId !== values.activityDataSourceId?.at(-1) ||
        initialData?.calculationMethodId !== values.calculationMethodId ||
        (initialData?.categoryId !== values.categoryId && values.categoryId) ||
        (initialData?.subcategoryId !== values.subcategoryId && values.subcategoryId) ||
        ((values.supplierId !== 'default' || !!initialData?.supplierId) &&
          initialData?.supplierId !== values.supplierId &&
          (form.getFieldInstance(GenericFieldKey.SUPPLIER) ||
            form.getFieldValue(GenericFieldKey.SUPPLIER)))
    }

    if (values.supplierId === 'default') {
      values = { ...values, supplierId: null }
    }

    if (currentLogEntryId.current && hasChanged && inputs.current.length) {
      performingMutation.current = true
      const logEntry = (await updateProductLogEntry(product.id, currentLogEntryId.current, {
        ...values,
        inputs: inputs.current.map((x) => ({
          inputKey: x.inputKey,
          value: x.value,
          values: x.values,
          unitId: x.unitId,
          address: x.address,
        })),
        productlifecycleStep,
        productId: product.id,
        territoryId: values.territoryId?.at(-1),
      })) as unknown as ProductLogEntry
      if (logEntry) {
        currentLogEntryId.current = logEntry.id
        previousLogEntry.current = logEntry
        await getPreviewCalculations(logEntry.id)
      }
      performingMutation.current = false
    } else {
      if (!currentLogEntryId.current && userOptions?.length === inputs.current?.length) {
        performingMutation.current = true
        const logEntries = (await createManyProductLogEntries([
          {
            ...values,
            inputs: inputs.current,
            productlifecycleStep,
            productId: product.id,
            territoryId: values.territoryId?.at(-1),
          },
        ])) as unknown as ProductLogEntry[]

        if (logEntries?.[0]) {
          currentLogEntryId.current = logEntries[0].id
          previousLogEntry.current = logEntries[0]

          await getPreviewCalculations(logEntries[0].id)
        }
        performingMutation.current = false
      }
    }
  }

  const debouncedUpsertLogEntry = useCallback(debounce(upsertLogEntry, 1000), [
    form,
    inputs.current,
  ])

  const updateStepData = async (): Promise<void> => {
    const values = { ...form.getFieldsValue(), inputs: inputs.current }
    setStepData((prev) => {
      prev[index] = { ...values, inputs: inputs.current, productlifecycleStep }
      return [...prev]
    })
    const isValid =
      Object.entries(values)
        ?.filter(([key]) => key !== GenericFieldKey.SUPPLIER)
        .every(([, val]) => !!val) && Array.isArray(values.territoryId)
    const inputsHaveValue =
      inputs.current.every((x) => x.value !== undefined || x.address !== undefined) &&
      inputs.current.length

    if (isValid && form.isFieldsTouched() && inputsHaveValue && !loading) {
      debouncedUpsertLogEntry()
    }
  }

  const resetNextFieldsOnChange = (changedKey: GenericFieldKey): void => {
    if (changedKey === GenericFieldKey.TITLE) {
      return
    }
    const allKeys = fields
      .filter((field) => !NO_DISABLE_KEYS.includes(field.key))
      .map((field) => field.key)
    const changedKeyIndex = allKeys.findIndex((el) => el === changedKey)
    const keysToReset = allKeys.slice(changedKeyIndex + 1)
    form.resetFields(keysToReset)
  }

  const onFormChange = (
    changedValues: Partial<CreateProductLogEntry>,
    allValues: CreateProductLogEntry,
  ): void => {
    const changedKey = Object.keys(changedValues)[0] as GenericFieldKey
    const adsIds = changedValues[GenericFieldKey.ACTIVITY_DATA_SOURCE]
    setName(allValues[GenericFieldKey.TITLE])
    switch (Object.keys(changedValues)[0]) {
      case GenericFieldKey.LOG_CATEGORY:
        setCategoryId(changedValues[GenericFieldKey.LOG_CATEGORY])
        resetNextFieldsOnChange(changedKey)

        break
      case GenericFieldKey.LOG_SUBCATEGORY:
        setSubcategoryId(changedValues[GenericFieldKey.LOG_SUBCATEGORY])
        resetNextFieldsOnChange(changedKey)

        break
      case GenericFieldKey.CALCULATION_METHOD:
        setCalculationMethodId(changedValues[GenericFieldKey.CALCULATION_METHOD])
        resetNextFieldsOnChange(changedKey)

        break
      case GenericFieldKey.ACTIVITY_DATA_SOURCE:
        if (
          initialData?.activityDataSourceId !==
          form.getFieldValue(GenericFieldKey.ACTIVITY_DATA_SOURCE)?.at(-1)
        ) {
          resetNextFieldsOnChange(changedKey)
          inputs.current = []
          setInitialData(undefined)
        }
        setActivityDataSourceId(Number(adsIds?.at(adsIds.length - 1)))
        break
    }
    updateStepData()
  }

  const getActivityDataSources = async (
    subcategoryId: number,
    calculationMethodId: number,
  ): Promise<void> => {
    const activityDataSources = await getActivityDataSourcesFromApi(
      subcategoryId,
      calculationMethodId,
    )
    if (activityDataSources) {
      setActivityDataSourceOptions(activityDataSources)
    }
  }

  const resetInputs = (): void => {
    setFields((prev) => {
      const untisIndex = prev.findIndex(({ key }) => key === GenericFieldKey.INPUTS)
      if (untisIndex === -1) {
        return prev
      }
      prev.splice(untisIndex, 1)
      return prev
    })
  }

  const continents = useMemo(() => {
    if (!territories?.length) {
      return []
    } else {
      const result =
        territories
          ?.filter((territory) => territory.continent?.alpha3Code === 'GLO')
          .map((territory) => {
            return { value: territory.id, label: territory.name, children: [] }
          }) ?? []

      const global = territories.find((territory) => territory.alpha3Code === 'GLO')
      if (global) {
        result.push({ value: global.id, label: global.name, children: [] })
      }
      return result
    }
  }, [territories])

  const possibleTerritories = useMemo(() => {
    if (!territories?.length) {
      return []
    } else {
      const result =
        territories
          ?.filter(
            (territory) =>
              !!territory.continent?.alpha3Code && territory.continent.alpha3Code !== 'GLO',
          )
          .reduce((sorted, next) => {
            const continent = continents.findIndex(
              (continent) => continent.value === next.continentId,
            )
            if (continent > -1) {
              sorted[continent].children.push({ value: next.id, label: next.name, children: [] })
            }
            return sorted
          }, continents as CascaderNode[]) ?? []

      result.forEach((continent: CascaderNode) =>
        continent.children.sort((a, b) => a.label.localeCompare(b.label)),
      )

      return result
    }
  }, [territories])

  useEffect(() => {
    if (possibleTerritories.length && continents.length && !initialData) {
      setTerrritoryFieldValue(product.territoryId)
    }
  }, [possibleTerritories, continents, initialData])

  useEffect(() => {
    if (categories) {
      setCategoryOptions(buildOptionsArray<Category>(categories) as SelectOptionsProperties[])
    }
    if (subcategories) {
      subcategoryOptions.current = buildOptionsArray<Subcategory>(
        subcategories,
      ) as SelectOptionsProperties[]
    }
    if (possibleTerritories?.length) {
      setTerritoriesOptions(possibleTerritories)
    }

    if (organizationSuppliers) {
      const supplierOptions = buildOptionsArray<Supplier>(
        organizationSuppliers,
      ) as SelectOptionsProperties[]
      setSuppliersOptions([
        {
          label: t('product.lifecycle-steps.we-are-the-provider'),
          value: 'default',
        },
        ...supplierOptions,
      ])
    }
    setFields([...fields])
  }, [categories, subcategories, possibleTerritories, organizationSuppliers])

  const onDeleteEntry = async (index: number): Promise<void> => {
    deleteEntry(index)
    if (currentLogEntryId.current) {
      await deleteProductLogEntry(product.id, currentLogEntryId.current)
    }
  }

  useEffect(() => {
    if (calculationMethodId && subcategoryId) {
      getActivityDataSources(subcategoryId, calculationMethodId)
    }
  }, [calculationMethodId, subcategoryId])

  useEffect(() => {
    if (currentStep > -1) {
      setStepName(stepsToDisplay[currentStep]?.title)
    }
  }, [currentStep])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (activityDataSourceId && calculationMethodId && subcategoryId) {
        const userOptionsFromRequest = await getUserOptionsForLogCreation(
          subcategoryId,
          calculationMethodId,
          activityDataSourceId,
        )
        setUserOptions([...userOptionsFromRequest])
      }
    }
    fetchData()
    resetInputs()
  }, [subcategoryId, activityDataSourceId, calculationMethodId])

  useEffect(() => {
    const formValues = form.getFieldsValue([
      GenericFieldKey.ACTIVITY_DATA_SOURCE,
      GenericFieldKey.LOG_SUBCATEGORY,
      GenericFieldKey.CALCULATION_METHOD,
    ])
    if (
      userOptions &&
      formValues[GenericFieldKey.ACTIVITY_DATA_SOURCE] &&
      formValues[GenericFieldKey.LOG_SUBCATEGORY] &&
      formValues[GenericFieldKey.CALCULATION_METHOD]
    ) {
      const unitsComponent = (
        <UserOptionInput
          userOptions={userOptions}
          setInputs={debounce((val) => {
            inputs.current = [...val]
            updateStepData()
          }, 200)}
          calculationMethod={calculationMethods?.find(
            (x) => x.id.toString() === calculationMethodId?.toString(),
          )}
          subcategory={
            subcategories?.find((subcategory) => subcategory.id === subcategoryId) as Subcategory
          }
          inputs={(inputs.current as unknown as Input[]) || []}
          isProductLog={true}
        />
      )

      setFields((prev) => {
        const adsId = prev.findIndex((field) => field.key === GenericFieldKey.ACTIVITY_DATA_SOURCE)
        const alreadyExists = prev.some((field) => field.key === GenericFieldKey.INPUTS)
        prev.splice(adsId + 1, alreadyExists ? 1 : 0, {
          component: unitsComponent,
          key: GenericFieldKey.INPUTS,
        })
        return [...prev]
      })
    }
  }, [userOptions, inputs.current])

  useEffect(() => {
    if (userOptions.length && inputs.current.length === userOptions.length) {
      updateActiveFields()
    }
  }, [inputs.current])

  useEffect(() => {
    const activeFieldsInit = Object.values(fields).reduce(
      (acc, curr, i) => ({ ...acc, [curr.key]: !i }),
      {},
    )
    getOrganizationSuppliers()
    setActiveFields(activeFieldsInit)
  }, [])

  const addSupplier = async (supplier: Supplier): Promise<void> => {
    setSupplierModalVisible(false)
    const fetchedSupplier = await createSupplier(supplier)
    if (fetchedSupplier) {
      form.setFields([{ name: GenericFieldKey.SUPPLIER, value: fetchedSupplier.id }])
      await getOrganizationSuppliers()
      message.success(t('suppliers.success'))
    } else {
      message.error(t('suppliers.error'))
    }
  }

  const gridifyUserOptionInputs = useCallback(
    (
      key: GenericFieldKey,
    ): {
      xs?: number
      sm?: number
      md?: number
      lg?: number
      xl?: number
    } => {
      const needsBiggerGrid = key === GenericFieldKey.INPUTS && userOptions.length > 1
      return {
        sm: 24,
        md: needsBiggerGrid ? 24 : 12,
        lg: needsBiggerGrid ? 12 : 8,
        xl: needsBiggerGrid ? 12 : 8,
      }
    },
    [fields, userOptions],
  )

  return (
    <>
      <Spin spinning={loading}>
        <Row className={classes.titleWrapper}>
          <Col span={24}></Col>
          <Col className={classes.logEntryTitle} span={12}>
            {name ? name : `${t('completeForm')}`}
          </Col>
          <Col span={12}>
            <Row justify="end">
              {loadingPreview ? (
                <Col className={classes.previewValue}>
                  <Skeleton.Button shape="round" size="small" active={loadingPreview} />
                </Col>
              ) : (
                <Pill size="sm" className={classes.badge}>
                  {currentLogEntryId.current
                    ? formatNumber(convertTonnesToGrams(Number(carbonValue ?? 0))) ?? '----'
                    : '----'}
                </Pill>
              )}
              <Col className={classes.previewValue}> (gCO2e)</Col>
            </Row>
          </Col>
        </Row>
        <Row className={classes.mainWrapper}>
          <Row>
            {activeFields && (
              <Form
                form={form}
                category={AnalyticsCategories.PRODUCTS}
                layout="inline"
                onValuesChange={debounce(onFormChange, 500)}
                disabled={loading}
              >
                <Row gutter={[0, 40]} justify="start">
                  {fields.map(({ key, component, label, additionalComponent, tooltip }) => {
                    return (
                      <Col
                        key={key}
                        className={classes.inputField}
                        {...gridifyUserOptionInputs(key)}
                      >
                        <>
                          <Row>
                            <Col>{label && <Label>{label}</Label>}</Col>
                            {tooltip && (
                              <Col>
                                <Col className={classes.tooltipIcon}>
                                  <Tooltip title="" subTitle={tooltip}>
                                    <HiOutlineInformationCircle color={GRAY_400} />
                                  </Tooltip>
                                </Col>
                              </Col>
                            )}
                          </Row>
                          {key !== GenericFieldKey.INPUTS ? (
                            <Form.Item name={key}>
                              {addExtraProps(component, key, {
                                disabled: !NO_DISABLE_KEYS.includes(key)
                                  ? !activeFields[key]
                                  : false,
                                onChange: () => updateActiveFields(),
                              })}
                            </Form.Item>
                          ) : (
                            component
                          )}
                          {additionalComponent &&
                            addExtraProps(additionalComponent, 'additional', {
                              className: classes.addButton,
                              onClick: () => setSupplierModalVisible(true),
                            })}
                        </>
                      </Col>
                    )
                  })}
                </Row>
              </Form>
            )}
            <Col span={24}>
              <Row justify="end">
                <Col>
                  <Button
                    type="ghost"
                    size="md"
                    prefixIcon={<HiTrash />}
                    category={AnalyticsCategories.PRODUCTS}
                    action="Remove Log Entry"
                    onClick={() => onDeleteEntry(index)}
                    className={classes.deleteButton}
                    iconClass={classes.deleteIcon}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Spin>
      <SuppliersModal
        createSupplier={addSupplier}
        isVisible={supplierModalVisible}
        onClose={() => {
          setSupplierModalVisible(false)
        }}
        featuresAllowed={featuresAllowed}
      />
    </>
  )
}

export default forwardRef(GenericStep)
