import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import Text from '@/atoms/Text'

import errorImage from '@/assets/error-general.svg'

import classes from '../Log.module.less'

type Params = {
  loadingLabel: string
  longLoadingLabel: string
  errorTitle: string
  errorDescription: string
  isLoading: boolean
  error?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTableLoading = ({
  loadingLabel,
  longLoadingLabel,
  errorTitle,
  errorDescription,
  isLoading,
  error,
}: Params) => {
  const [isLongLoading, setIsLongLoading] = React.useState(false)

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLongLoading(true)
      }, 7000)
    } else {
      setIsLongLoading(false)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading])

  const emptyState = React.useMemo(
    () =>
      isLoading || !error
        ? {}
        : {
            emptyTitle: errorTitle,
            emptyDescription: errorDescription,
            emptyImage: errorImage,
          },
    [error, isLoading],
  )

  return {
    emptyState,
    spinning: isLoading,
    indicator: (
      <div className={classes.tableLoading}>
        <LoadingOutlined style={{ fontSize: 16 }} spin />
        <Text fontWeight="bold" size="lg" className={classes.tableLoadingText}>
          {loadingLabel}
        </Text>
      </div>
    ),
    tip: isLongLoading ? (
      <Text
        fontWeight="medium"
        style={{ whiteSpace: 'pre-line' }}
        className={classes.tableLoadingText}
      >
        {longLoadingLabel}
      </Text>
    ) : undefined,
  }
}
