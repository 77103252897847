import React from 'react'

import { IconProps } from '@/types/general'

const EyeOutline = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...rest
}: IconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51227 12C4.74799 15.4965 8.08311 18 12.0005 18C15.9178 18 19.2529 15.4966 20.4886 12C19.2529 8.50345 15.9178 6 12.0004 6C8.08311 6 4.74801 8.50342 3.51227 12ZM1.5042 11.7003C2.90562 7.23842 7.07354 4 12.0004 4C16.9274 4 21.0953 7.23846 22.4967 11.7004C22.558 11.8955 22.558 12.1046 22.4967 12.2997C21.0953 16.7616 16.9274 20 12.0005 20C7.07353 20 2.9056 16.7615 1.5042 12.2996C1.44293 12.1045 1.44294 11.8954 1.5042 11.7003ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
        fill={color}
      />
    </svg>
  )
}

export default EyeOutline
