import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { OrganizationCarboonFootprintFormValues } from '@/pages/Log/LocationLogs/OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'

import { DEFAULT_STATE, SLICE_NAME } from './constants'

const logEntriesOverviewSlice = createSlice({
  name: SLICE_NAME,
  initialState: DEFAULT_STATE,
  reducers: {
    setOrganizationEmissionsFilters: (
      state,
      action: PayloadAction<OrganizationCarboonFootprintFormValues>,
    ) => {
      if (state.filters) {
        state.filters = action.payload
      }
    },
    resetOrganizationEmissionsFilters: (state) => {
      state.filters = DEFAULT_STATE.filters
    },
  },
})

export const { setOrganizationEmissionsFilters, resetOrganizationEmissionsFilters } =
  logEntriesOverviewSlice.actions

export default logEntriesOverviewSlice
