import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiDotsVertical,
  HiOutlineInformationCircle,
  HiPencilAlt,
  HiPlus,
  HiTrash,
} from 'react-icons/hi'
import { useNavigate } from 'react-router'

import {
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Spin,
  TablePaginationConfig,
  Tag,
  Typography,
} from 'antd/es'
import { SorterResult } from 'antd/es/table/interface'

import { WarningOutlined } from '@ant-design/icons'
import moment from 'moment'

import { ClosedPeriodStatus } from '@cozero/constants'
import { BusinessUnit, ClosedPeriod, User } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Avatar from '@/atoms/Avatar'
import Button from '@/atoms/Button'
import Pill from '@/atoms/Pill'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useClosedPeriod } from '@/hooks/useClosedPeriod'
import { useAppSelector } from '@/redux'
import { getIsManager, getIsManagerOrAdmin, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { CINDER_BLUE_60 } from '@/styles/variables'

import classes from './ClosedPeriods.module.less'
import OutdatedEmissionFactorsModal from './Modals/OutdatedEmissionFactorsModal'

const INITIAL_PAGE_SIZE = 5
const INITIAL_PAGE = 1

export type ClosedPeriodTableSorter =
  | Record<string, string>
  | Record<string, Record<string, string>>
  | undefined

const ClosedPeriods = (): JSX.Element => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const isManager = useAppSelector(getIsManager)
  const isManagerOrAdmin = useAppSelector(getIsManagerOrAdmin)
  const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalRecords, setTotalRecords] = useState(1)
  const [openOutdatedEmissionFactorsModal, setOpenOutdatedEmissionFactorsModal] = useState(false)
  const [selectedClosedPeriod, setSelectedClosedPeriod] = useState<ClosedPeriod>()
  const [pagination, setPagination] = useState<TablePaginationConfig>()
  const [sorters, setSorters] = useState<SorterResult<ClosedPeriod>>({})
  const { getManyClosedPeriods, deleteClosedPeriod } = useClosedPeriod()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const getClosedPeriods = async (
    page: number,
    pageSize: number,
    sorter: ClosedPeriodTableSorter,
  ): Promise<void> => {
    setLoading(true)
    const { docs, totalRecords: total } = await getManyClosedPeriods({ page, pageSize, sorter })
    setClosedPeriods(docs)
    setTotalRecords(total)
    setLoading(false)
  }

  const transformTableSorter = (sorter: SorterResult<ClosedPeriod>): ClosedPeriodTableSorter => {
    if (!sorter || !Object.keys(sorter).length) {
      return undefined
    }

    // Deal with nested sort keys like ['businessUnit', 'title']
    if (Array.isArray(sorter.field)) {
      const firstKey: string = sorter.field[0]
      const secondKey: string = sorter.field[1]

      return {
        [firstKey]: {
          [secondKey]: sorter.order === 'descend' ? 'desc' : 'asc',
        },
      }
    } else {
      return {
        [sorter.field as string]: sorter.order === 'descend' ? 'desc' : 'asc',
      }
    }
  }

  const onTableChange = (page: number, sorter: SorterResult<ClosedPeriod>): void => {
    getClosedPeriods(page, INITIAL_PAGE_SIZE, transformTableSorter(sorter))
  }

  const openModal = (closedPeriod: ClosedPeriod): void => {
    setSelectedClosedPeriod(closedPeriod)
    return setOpenOutdatedEmissionFactorsModal(true)
  }

  const deleteClosedPeriodAndRefreshTable = async (id: number): Promise<void> => {
    setLoading(true)
    await deleteClosedPeriod(id.toString())
    await getClosedPeriods(
      pagination?.current ? pagination.current : INITIAL_PAGE,
      pagination?.pageSize ? pagination.pageSize : INITIAL_PAGE_SIZE,
      sorters ? transformTableSorter(sorters) : undefined,
    )
    setLoading(false)
  }

  useEffect(() => {
    if (!openOutdatedEmissionFactorsModal) {
      getClosedPeriods(INITIAL_PAGE, INITIAL_PAGE_SIZE, undefined)
    }
  }, [openOutdatedEmissionFactorsModal, selectedBusinessUnit?.id])

  const menu = (id: number, usedInAct: boolean): JSX.Element => (
    <Menu style={{ width: 256 }} mode="vertical">
      <Menu.ItemGroup>
        <Menu.Item key="1">
          <Button
            category={AnalyticsCategories.CLOSED_PERIODS}
            action="Edit closed period"
            type="text"
            prefixIcon={<HiPencilAlt />}
            onClick={() => {
              navigate(routes.log.carbonFootprint.editClosedPeriod.replace(':id', id.toString()))
            }}
          >
            {t('log.closed-period.edit')}
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Tooltip
            placement="bottom"
            title={t('custom-reports.closed-period.delete-disabled')}
            disabled={!usedInAct}
          >
            <Popconfirm
              title={t('log.closed-period.delete-confirmation')}
              onConfirm={() => deleteClosedPeriodAndRefreshTable(id)}
              disabled={usedInAct}
            >
              <Button
                category={AnalyticsCategories.CLOSED_PERIODS}
                action="Delete closed period"
                type="text"
                prefixIcon={<HiTrash />}
                color="danger"
                disabled={usedInAct}
              >
                {t('log.closed-period.delete')}
              </Button>
            </Popconfirm>
          </Tooltip>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const columns = [
    {
      title: t('custom-reports.column-name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('custom-reports.column-status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        return (
          <Pill color={record.status === ClosedPeriodStatus.DRAFT ? 'cinder-blue' : 'blue'}>
            {t(`custom-reports.${record.status}`)}
          </Pill>
        )
      },
    },
    {
      title: t('custom-reports.column-description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('custom-reports.column-date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        return (
          <Space className={classes.row}>
            <Tag className={classes.tag}>
              {moment(record.startDate)
                .utc()
                .locale(user?.locale ?? 'en')
                .format('LL')}
            </Tag>
            <Typography className={classes.periodDivisor}>{t('to')}</Typography>
            <Tag className={classes.tag}>
              {moment(record.endDate)
                .utc()
                .locale(user?.locale ?? 'en')
                .format('LL')}
            </Tag>
          </Space>
        )
      },
    },
    {
      title: t('custom-reports.column-organization-level'),
      dataIndex: 'organizationLevel',
      key: 'organizationLevel',
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        if (record.organizationLevel === 'businessUnit') {
          return <div>{record.businessUnit.title}</div>
        } else {
          return <div>{t('log.closed-period.organization-levels.entire-organization')}</div>
        }
      },
    },
    {
      title: t('custom-reports.column-created-by'),
      dataIndex: ['responsible', 'email'],
      key: 'responsible',
      width: 200,
      sorter: true,
      render(_: string, record: ClosedPeriod) {
        const user = record.responsible as User
        if (!user) {
          return <Typography>N/A</Typography>
        }
        return (
          <Space className={classes.row} align="center" direction="horizontal">
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              image={user.photo}
              className={classes.userPhoto}
              showTooltip={false}
            />
            <Typography>{user?.firstName || user?.email}</Typography>
          </Space>
        )
      },
    },
    {
      title: t('business-unit.name'),
      dataIndex: ['businessUnit', 'title'],
      key: 'businessUnit',
      sorter: (a: ClosedPeriod, b: ClosedPeriod) =>
        (a.businessUnit as BusinessUnit)?.title.localeCompare(
          (b.businessUnit as BusinessUnit)?.title,
        ),
    },
    {
      title: t('actions.title-plural'),
      key: 'action',
      fixed: 'right',
      render(_text: string, record: ClosedPeriod) {
        if (!isManagerOrAdmin || (isManager && record.organizationLevel === 'organization')) {
          return
        }
        return (
          <Row justify="space-between">
            {record.outdatedEmissionFactors?.length ? (
              <Col span={18}>
                <Space className={classes.actions}>
                  <Button
                    prefixIcon={<WarningOutlined />}
                    category={AnalyticsCategories.CLOSED_PERIODS}
                    action="open-modal"
                    onClick={(e) => {
                      e?.stopPropagation()
                      openModal(record)
                    }}
                    type="primary"
                  >
                    {t('custom-reports.column-action')}
                  </Button>
                </Space>
              </Col>
            ) : null}
            <Col span={6}>
              <Dropdown
                overlay={() => menu(record.id, record.usedInAct)}
                className={classes.dropdown}
              >
                <Button
                  category={AnalyticsCategories.CLOSED_PERIODS}
                  action="Show options menu"
                  type="text"
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <HiDotsVertical color={CINDER_BLUE_60} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Typography.Text className={classes.headline}>
                {t('layout.closed-periods')}
              </Typography.Text>
            </Col>
            <Col xxl={12} xl={12} lg={16} span={24}>
              <Typography.Text className={classes.subtitle}>
                {t('custom-reports.description')}
              </Typography.Text>
            </Col>
          </Row>
          <div className={classes.header} />
        </Col>
        <Divider />
        <Col span={24}>
          <Row className={classes.createBtn} justify="end">
            {isManagerOrAdmin && (
              <Button
                prefixIcon={<HiPlus />}
                type="primary"
                action="Closed period"
                category={AnalyticsCategories.LOCATIONS}
                onClick={() => navigate(routes.log.carbonFootprint.addClosedPeriod)}
              >
                {t('log.carbon-footprint-tabs.closed-period')}
              </Button>
            )}
          </Row>
          <Row className={classes.section} gutter={16}>
            <Col span={24}>
              <Table
                scroll={{ x: 'max-content' }}
                onChange={(pagination, _, sorters) => {
                  setPagination(pagination)
                  setSorters(sorters as SorterResult<ClosedPeriod>)

                  return onTableChange(
                    pagination.current ?? 1,
                    sorters as SorterResult<ClosedPeriod>,
                  )
                }}
                pagination={{
                  total: totalRecords,
                  pageSize: INITIAL_PAGE_SIZE,
                  defaultCurrent: INITIAL_PAGE,
                }}
                className={classes.closedPeriodsTable}
                rowClassName={classes.tableRow}
                dataSource={closedPeriods}
                rowKey="id"
                // Types not working properly for 'fixed'
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                columns={columns as any}
                onRow={(record) => ({
                  onClick: () => {
                    navigate(`${routes.log.customReport.replace(':id', record.id.toString())}`)
                  },
                })}
                showWrapper={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {selectedClosedPeriod && (
        <OutdatedEmissionFactorsModal
          closedPeriod={selectedClosedPeriod}
          open={true}
          visibilityHandler={(open: boolean) => {
            return setOpenOutdatedEmissionFactorsModal(open)
          }}
          onClose={() => setOpenOutdatedEmissionFactorsModal(false)}
        />
      )}
    </Spin>
  )
}

export default ClosedPeriods
