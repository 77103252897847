import React, {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineX, HiQuestionMarkCircle } from 'react-icons/hi'

import { Modal as AntModal, ModalProps as AntdModalProps, Col, Row, RowProps } from 'antd/es'

import Button, { ButtonProps } from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { CINDER_BLUE_50, SPACING_SM, SPACING_XS } from '@/styles/variables'

import classes from './Modal.module.less'

export interface ModalProps extends Omit<AntdModalProps, 'cancelButtonProps' | 'okButtonProps'> {
  title?: Required<string>
  children?: ReactNode
  wikiLink?: string
  noBodyPadding?: boolean
  hideFooter?: boolean
  hideCancel?: boolean
  cancelButtonProps?: ButtonProps & {
    category?: string
    action?: string
    color?: 'blue' | 'orange' | 'green'
  }

  okButtonProps?: Partial<ButtonProps> & {
    category?: string
    action?: string
    color?: 'blue' | 'orange' | 'green' | 'danger'
  }
  customHeader?: ReactElement
  bodyFullHeight?: boolean
  variant?: 'default' | 'small' | 'wide'
  minWidth?: string
}

const CloseIcon = ({
  handleCancel,
}: {
  handleCancel: (e: React.MouseEvent<HTMLElement>) => void
}): JSX.Element => (
  <Button
    style={{ padding: SPACING_XS, verticalAlign: 'middle' }}
    category={AnalyticsCategories.UNKNOWN}
    action="Close modal"
    type="text"
    className={classes.closeButton}
    onClick={handleCancel}
    prefixIcon={<HiOutlineX size={14} />}
  />
)

/**
 * The top title of the modal
 */
const ModalTitle = ({ title, wikiLink, customHeader }: Partial<ModalProps>): ReactElement => {
  return customHeader ? (
    customHeader
  ) : (
    <div className={classes.title}>
      <div className={classes.titleText}>{title}</div>
      {wikiLink && (
        <a href={wikiLink} className={classes.titleIcon} target="_blank" rel="noreferrer">
          <HiQuestionMarkCircle />
        </a>
      )}
    </div>
  )
}

/**
 * The modal footer showing a save button and/or cancel button
 */
export const ModalFooter = ({
  onOk,
  onCancel,
  cancelButtonProps,
  cancelText,
  okButtonProps,
  okText,
  variant = 'default',
  confirmLoading,
}: Partial<ModalProps>): ReactElement | null => {
  const { t } = useTranslation()
  const wrapperClasses = [classes.footer, variant === 'small' ? classes.small : ''].join(' ')

  const justifyRowType = useCallback((variant: ModalProps['variant']): RowProps['justify'] => {
    switch (variant) {
      case 'small':
        return 'center'
      case 'wide':
        return 'space-between'
      default:
        return 'end'
    }
  }, [])

  if (onOk || onCancel) {
    return (
      <div className={wrapperClasses}>
        <Row gutter={16} justify={justifyRowType(variant)}>
          <Col>
            {onCancel && (
              <Button
                category={cancelButtonProps?.category ?? AnalyticsCategories.UNKNOWN}
                action={cancelButtonProps?.action ?? 'cancel'}
                type="secondary"
                onClick={onCancel}
              >
                <span>{cancelText ?? t('actions.cancel')}</span>
              </Button>
            )}
          </Col>
          <Col>
            {onOk && (
              <Button
                category={okButtonProps?.category ?? AnalyticsCategories.UNKNOWN}
                action={okButtonProps?.action ?? 'ok'}
                type="primary"
                onClick={onOk}
                data-cy="ok-modal-button"
                color={okButtonProps?.color === 'danger' ? 'danger' : undefined}
                disabled={okButtonProps?.disabled || confirmLoading}
                prefixIcon={okButtonProps?.prefixIcon}
                loading={confirmLoading}
              >
                <span data-cy={okText}>{okText ?? t('actions.ok')}</span>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
  }
  return null
}

/**
 * A basic wrapper for all modals
 *
 * @param {string} props.title the title of the modal
 * @param {ReactNode} props.children the modal content
 * @param {string} props.title the title shown in the modal
 * @param {string} [wikiLink] the link to a relevant wiki page, shown next to the title
 * @param {boolean} [noBodyPadding=false] should the body of the modal have padding
 */
const Modal = ({
  title,
  children,
  className,
  visible,
  onCancel,
  onOk,
  wikiLink,
  noBodyPadding = false,
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  hideFooter,
  hideCancel,
  variant,
  confirmLoading,
  width = '50%',
  minWidth,
  ...rest
}: ModalProps): JSX.Element => {
  const modalClasses = useMemo(() => {
    const classArray = [classes.modal, className]
    return classArray.join(' ')
  }, [className])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleOk = (e: React.MouseEvent<HTMLElement>): void => {
    if (onOk) {
      onOk(e)
    }
    setIsModalVisible(false)
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>): void => {
    if (onCancel) {
      onCancel(e)
    }
    setIsModalVisible(false)
  }

  useEffect(() => {
    setIsModalVisible(visible ?? false)
  }, [visible])

  const ModalBox = (): JSX.Element => {
    return (
      <div className={modalClasses}>
        {title && (
          <Row className={classes.modalHeader} align="middle">
            <Col flex={1} className={classes.modalTitle}>
              <ModalTitle title={title} wikiLink={wikiLink} />
            </Col>
            <Col>
              <CloseIcon handleCancel={handleCancel} />
            </Col>
          </Row>
        )}
        <div
          style={rest.bodyStyle}
          className={`${classes.modalBody} ${noBodyPadding ? classes.modalBodyNoPadding : ''}`}
        >
          {children}
        </div>
        {!hideFooter && (
          <ModalFooter
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            hideCancel={hideCancel}
            variant={variant}
            confirmLoading={confirmLoading}
          />
        )}
      </div>
    )
  }

  const style: {
    top?: string
    minWidth?: string
  } = { top: '50px' }
  if (minWidth) {
    style.minWidth = minWidth
  }

  return (
    <AntModal
      open={isModalVisible}
      title={title ? <ModalTitle /> : null}
      onCancel={handleCancel}
      onOk={handleOk}
      modalRender={ModalBox}
      closeIcon={<CloseIcon handleCancel={handleCancel} />}
      style={style}
      width={width}
      {...rest}
    />
  )
}

export default memo(Modal)
