import { FactorRequest } from '@cozero/models'
import { centralApiGatewayClient, logApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { FIND_FACTOR_REQUEST, FIND_FACTOR_REQUEST_SUPPLIER } from './constants'

const factorsRequestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [FIND_FACTOR_REQUEST]: builder.query<FactorRequest[], string>({
      query: (status) => ({
        url: logApiGatewayClient.factorRequests.GET_MANY,
        method: 'GET',
        params: {
          status,
        },
      }),
    }),
    [FIND_FACTOR_REQUEST_SUPPLIER]: builder.query<FactorRequest[], string>({
      query: (email) => ({
        url: centralApiGatewayClient.suppliers.GET_FACTOR_REQUESTS,
        method: 'GET',
        params: {
          supplier: email,
        },
      }),
    }),
  }),
})

export const { useLazyFindFactorRequestQuery, useLazyFindFactorRequestSupplierQuery, usePrefetch } =
  factorsRequestsApiSlice

export default factorsRequestsApiSlice
