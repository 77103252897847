import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { AutoComplete, Col, Form, Input, Row, Typography, message } from 'antd/es'

import orderBy from 'lodash-es/orderBy'
import debounce from 'lodash/debounce'

import {
  CalculationMethod,
  ClientUserOption,
  Geolocation,
  Input as PrismaInput,
  SelectOption,
  Subcategory,
  Unit,
} from '@cozero/models'

import Alert from '@/atoms/Alert'
import CustomInputNumber from '@/atoms/CustomInputNumber'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { MAX_VALUE } from '@/constants/numericPrecision'
import { useLazyGetAddressQuery } from '@/redux/locations'
import { COZERO_BLUE_50 } from '@/styles/variables'
import { capitalize } from '@/utils/string'

import UserOptionSelect from './UserOptionSelect'
import classes from './classes.module.less'

interface Props {
  userOptions: ClientUserOption[]
  calculationMethod?: CalculationMethod
  setInputs: (inputs: PrismaInput[]) => void
  subcategory: Subcategory
  inputs: PrismaInput[]
  isProductLog: boolean
}

// In some cases, we need to show the user option only if the user has answer positively to a parent question
const userOptionFilter = (
  option: ClientUserOption,
  options: ClientUserOption[],
  inputs: PrismaInput[],
): boolean => {
  if (!option.amendment) {
    return true
  }
  const hasParents = options.find((obj) => !!obj.parentKey)
  if (hasParents) {
    const parentInput = inputs.find((obj) => obj.inputKey === hasParents.parentKey)
    if (option.inputKey === hasParents.parentKey) {
      return true
    } else if (!parentInput || !parentInput.value) {
      return false
    }
  }

  return true
}

function UserOptionInput({
  userOptions,
  calculationMethod,
  subcategory,
  inputs,
  setInputs,
  isProductLog = true,
}: Props): JSX.Element {
  const { t, i18n } = useTranslation('common')
  const [inputOptions, setInputOptions] = useState<ClientUserOption[]>([])
  const [getAddressesAutocomplete] = useLazyGetAddressQuery()
  const [originAddresses, setOriginAddresses] = useState<Geolocation[]>([])
  const [destinationAddresses, setDestinationAddresses] = useState<Geolocation[]>([])

  async function fetchAndSetOptions(
    searchText: string,
    optionKey: 'origin' | 'destination',
  ): Promise<void> {
    try {
      const results = await getAddressesAutocomplete(searchText).unwrap()
      if (results) {
        if (optionKey === 'origin') {
          setOriginAddresses(results)
        } else {
          setDestinationAddresses(results)
        }
      }
    } catch (err) {
      message.error(t('location.error'))
    }
  }

  async function searchAddress(
    searchText: string,
    optionKey: 'origin' | 'destination',
  ): Promise<void> {
    await fetchAndSetOptions(searchText, optionKey)
  }

  const debounceFn = useCallback(debounce(searchAddress, 500), [])

  const setInput = (
    value: number | string | null,
    keyType: 'default' | 'number' | 'location' | 'unitId' | 'boolean' | 'object' | 'string',
    option: ClientUserOption,
  ): void => {
    if (value === undefined || value === null) {
      return
    }
    const newInputs = [...inputs] as PrismaInput[]
    const inputIndex = newInputs.findIndex((obj) => obj.inputKey === option.inputKey)
    const defaultUnit = orderBy(
      userOptions.find((obj) => obj.inputKey === option.inputKey)?.units,
      'name',
    )[0]

    if (inputIndex > -1) {
      if (keyType === 'unitId') {
        newInputs[inputIndex] = {
          ...newInputs[inputIndex],
          unitId: parseInt(`${value}`),
        }
      } else if (keyType === 'location') {
        newInputs[inputIndex] = {
          inputKey: option.inputKey,
          address: value as string,
        } as PrismaInput
      } else if (keyType === 'object') {
        newInputs[inputIndex] = {
          ...newInputs[inputIndex],
          value: 0,
          values: value as string,
        }
      } else {
        newInputs[inputIndex] = {
          ...newInputs[inputIndex],
          value: parseFloat(`${value}`),
        }
      }

      setInputs([...newInputs])
    } else {
      if (keyType === 'unitId') {
        setInputs([
          ...newInputs,
          {
            inputKey: option.inputKey,
            unitId: parseInt(`${value}`),
          } as PrismaInput,
        ])
      } else if (keyType === 'location') {
        setInputs([
          ...newInputs,
          {
            inputKey: option.inputKey,
            address: value as string,
          } as PrismaInput,
        ])
      } else {
        setInputs([
          ...newInputs,
          {
            inputKey: option.inputKey,
            value: parseFloat(`${value}`),
            unitId: defaultUnit?.id,
          } as PrismaInput,
        ])
      }
    }
  }

  useEffect(() => {
    setInputOptions([])
  }, [calculationMethod])

  useEffect(() => {
    setInputOptions(userOptions.filter((option) => userOptionFilter(option, userOptions, inputs)))
  }, [inputs, userOptions])

  useEffect(() => {
    // When the user changes ADS reset the inputs unless there is a value there
    if (!inputs.find((obj) => obj.value || obj.address)) {
      setInputs([])
    }
  }, [userOptions])

  const getUserOptionSelectValue = (selection: SelectOption): string | number => {
    return selection.values ? JSON.stringify(selection.values) : (selection.value as number)
  }
  // Show mandatory inputs first
  const sortedInputs = useMemo(() => {
    return inputOptions.sort((a, b) => {
      if (calculationMethod?.type.optionalInputs.includes(a.inputKey)) {
        return 1
      }
      if (calculationMethod?.type.optionalInputs.includes(b.inputKey)) {
        return -1
      }
      return 0
    })
  }, [inputOptions, calculationMethod])

  return (
    <Col span={24}>
      <Form.Item name="inputs">
        <div className={classes.inputsContainer}>
          {sortedInputs.map((option) => {
            const key = option.inputKey
            const units = (orderBy(option?.units, 'name') as Unit[]) ?? []
            const hasUnit = units.length > 0
            const hasSelections =
              !!option.selectOptions?.length ||
              option.inputKey === 'origin' ||
              option.inputKey === 'destination'
            const width = hasSelections ? '100%' : hasUnit ? '65%' : '100%'
            const selections = (
              Array.isArray(option.selectOptions)
                ? option.selectOptions
                : JSON.parse(option.selectOptions || '[]')
            ) as SelectOption[]
            const input = inputs.find((input) => input.inputKey === option?.inputKey)
            const lookupKey = calculationMethod?.type.key.includes('ecotransit')
              ? 'ecotransit'
              : calculationMethod?.type.key
            const labelTranslationKey = `log.create.units.${lookupKey}.${
              isProductLog ? 'product' + capitalize(key) : key
            }`
            const tooltipTranslationKey = `${labelTranslationKey}Tooltip`
            const unitName = i18n.exists(labelTranslationKey) ? t(labelTranslationKey) : ''
            const tooltip = i18n.exists(tooltipTranslationKey) ? t(tooltipTranslationKey) : ''

            return (
              <Row key={key} className={classes.inputRow}>
                <Row align="middle">
                  <Label>{calculationMethod && unitName}</Label>
                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <HiOutlineInformationCircle color={COZERO_BLUE_50} />
                    </Tooltip>
                  )}
                </Row>
                {option.optionType === 'location' && (
                  <AutoComplete
                    defaultValue={input?.address}
                    onChange={async (value) => {
                      debounceFn(value, option.inputKey as 'origin' | 'destination')
                    }}
                    onSelect={(value: string) => {
                      setInput(value, 'location', option)
                    }}
                    style={{ width, borderRadius: 4 }}
                    size="large"
                    key={option.inputKey}
                    options={(option.inputKey === 'origin'
                      ? originAddresses
                      : destinationAddresses
                    ).map((address) => ({
                      value: address.title,
                      label: address.title,
                    }))}
                  />
                )}
                <Input.Group className={classes.inputGroup}>
                  {option.optionType === 'default' && (
                    <CustomInputNumber
                      max={MAX_VALUE}
                      style={{ borderRadius: 4 }}
                      size="large"
                      className={classes.inputNumber}
                      onChange={(value) => setInput(value || 0, 'default', option)}
                      defaultValue={typeof input?.value === 'number' ? input?.value : undefined}
                      data-cy="entry-input"
                    />
                  )}
                  {option.optionType === 'number' && (
                    <UserOptionSelect
                      className={classes.unitSelect}
                      onChange={(value) => {
                        setInput(value, typeof value === 'number' ? 'number' : 'object', option)
                      }}
                      options={selections?.map((selection) => {
                        return { value: getUserOptionSelectValue(selection), label: selection.key }
                      })}
                      initialValue={
                        (!input?.values ? input?.value : input?.values) as number | string
                      }
                    />
                  )}
                  {option.optionType === 'string' && (
                    <InputField
                      size="large"
                      className={classes.unitSelect}
                      value={
                        !input?.values
                          ? input?.address
                            ? input?.address
                            : input?.value
                          : input?.values
                      }
                      onChange={(element) => {
                        const value = element.target.value
                        setInput(value, typeof value === 'number' ? 'number' : 'object', option)
                      }}
                      type="text"
                    />
                  )}
                  {option.optionType === 'boolean' && (
                    <UserOptionSelect
                      onChange={(value) => {
                        setInput(value, 'boolean', option)
                      }}
                      options={[
                        {
                          label:
                            selections?.find((selection) => selection.value === true)?.key ?? 'yes',
                          value: 1,
                        },
                        {
                          label:
                            selections?.find((selection) => selection.value === false)?.key ?? 'no',
                          value: 0,
                        },
                      ]}
                      initialValue={input?.value ? 1 : 0}
                    />
                  )}
                  {hasUnit &&
                    (units.length === 1 ? (
                      !hasSelections && (
                        <Typography style={{ paddingLeft: 5 }}>{units?.[0]?.name}</Typography>
                      )
                    ) : (
                      <UserOptionSelect
                        className={classes.unitSelect}
                        onChange={(value) => setInput(value, 'unitId', option)}
                        value={input?.unitId as number}
                        options={units.map((unit) => ({
                          value: unit.id,
                          label:
                            unit.key === 'unit'
                              ? t('log.create.units.generic', { name: subcategory.name })
                              : unit.name,
                        }))}
                        initialValue={input?.unitId ?? units?.[0]?.id}
                      />
                    ))}
                </Input.Group>
                {input && input.value < 0 && (
                  <Alert type={'info'} className={classes.alert}>
                    {<Text>{t('log.create.negative-input-warning')}</Text>}
                  </Alert>
                )}
              </Row>
            )
          })}
        </div>
      </Form.Item>
    </Col>
  )
}

export default UserOptionInput
