/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { Col, Row, Space } from 'antd'

import classNames from 'classnames'
import moment from 'moment'

import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'
import { TableHeaderCellPopover } from '@/molecules/Table/TableHeaderCellPopover/TableHeaderCellPopover'

import Button from '@/atoms/Button'
import Card from '@/atoms/Card'
import DateRangePicker from '@/atoms/DateRangePicker'
import Pill from '@/atoms/Pill'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { usePagination } from '@/hooks/useTableFetching/usePagination'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetSearchLogEntriesQuery } from '@/redux/logEntries'

import classes from './Log.module.less'
import { OrganizationCarbonFootprintFiltersDrawer } from './OrganizationCarbonFootprintFiltersDrawer/OrganizationCarbonFootprintFiltersDrawer'
import { OrganizationCarboonFootprintFormValues } from './OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'
import ExportDropdown from './components/ExportDropdown'
import { useColumnManager } from './hooks/useColumnManager'
import { useDateFilter } from './hooks/useDateFilter'
import { useFiltersCount } from './hooks/useFiltersCount'
import { useOrganizationEmissionsFiltersState } from './hooks/useOrganizationEmissionsFiltersState'
import { useSorting } from './hooks/useSorting'
import { useTableLoading } from './hooks/useTableLoading'

export const LogEntries = (): JSX.Element => {
  const columnManager = useColumnManager()
  const { currentSorting, setSorting } = useSorting()
  const [searchParams, setSearchParams] = useSearchParams()
  const { onChange, ...pagination } = usePagination({ searchParams, setSearchParams })
  const { dateFilter, onOpenChange, ranges } = useDateFilter()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { trackAction } = useMixpanel()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false)

  const [filters, setFilters] = useOrganizationEmissionsFiltersState()

  const { count } = useFiltersCount({ filters })

  const searchQuery = React.useMemo(
    () => ({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      ...(dateFilter?.[0] &&
        dateFilter?.[1] && {
          startDate: dateFilter[0],
          endDate: dateFilter[1],
        }),
      ...(!!currentSorting && {
        sortingKey: currentSorting.key,
        sortingDirection: currentSorting.direction,
      }),
      filters,
      selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
    }),
    [currentSorting, dateFilter, filters, pagination, selectedBusinessUnit?.id],
  )

  const {
    data: { total, unfilteredTotal, data: logEntries = [] } = {},
    isFetching,
    error,
  } = useGetSearchLogEntriesQuery(searchQuery, {
    skip: !pagination.current || !pagination.pageSize || !selectedBusinessUnit?.id,
  })

  const { emptyState, ...tableIndicator } = useTableLoading({
    loadingLabel: t('log.log-entries-overview.loading'),
    longLoadingLabel: t('log.log-entries-overview.loading-tip'),
    errorTitle: t('log.log-entries-overview.loading-error.title'),
    errorDescription: t('log.log-entries-overview.loading-error.description'),
    isLoading: isFetching,
    error: Boolean(error),
  })

  const columnsWithPopover = React.useMemo(
    () =>
      columnManager.columns.map((column) => ({
        ...column,
        title: (
          <TableHeaderCellPopover
            columnKey={column.key}
            title={column.title}
            sortable={column?.sortable}
            sorting={currentSorting}
            setSorting={setSorting}
            manageColumns={() =>
              columnManager.showModal('column-manager-accessed-via-column-header')
            }
            hideColumn={columnManager.hideColumn}
          />
        ),
        onHeaderCell: () => ({
          className: classes.cellHeader,
        }),
      })),
    [columnManager, currentSorting, setSorting],
  )

  const handleOnSubmit = useCallback(
    (filters: OrganizationCarboonFootprintFormValues) => {
      setFilters(filters)
      onChange({ ...pagination, current: 1 })
      trackAction(AnalyticsCategories.LOG_ENTRY_OVERVIEW, 'filters-changed', { filters })
    },
    [onChange, trackAction, setFilters, pagination],
  )

  return (
    <Card shadow="none" data-cy="hotspot-analysis-section" className={classes.logEntriesWrapper}>
      {columnManager.modal}

      <Row gutter={[16, 16]}>
        <Col flex={1} />

        <Col>
          <Button
            action="open-filter-drawer"
            onClick={() => {
              setOpenFilterDrawer(true)
            }}
            className={classNames(classes.buttonRow, { [classes.active]: count > 0 })}
            type="ghost"
            prefixIcon={<HiOutlineFilter />}
          >
            <Space>
              {t('act.climateActions.filter.title')}
              {count > 0 && (
                <Pill color="cinder-blue-10" className={classes.count}>
                  {count}
                </Pill>
              )}
            </Space>
          </Button>
        </Col>

        <Col>
          <Space align="center" size="middle">
            <DateRangePicker
              disabled={isFetching}
              ranges={ranges}
              inputReadOnly
              format="yyyy-MM-DD"
              picker="date"
              style={{ width: 250 }}
              value={[
                dateFilter?.[0] ? moment(dateFilter[0]) : null,
                dateFilter?.[1] ? moment(dateFilter[1]) : null,
              ]}
              onCloseChange={onOpenChange}
              data-cy="leo-date-picker"
            />
            <ExportDropdown
              searchQuery={searchQuery}
              visibleColumns={columnManager.visibleKeys}
              manageColumns={() => columnManager.showModal('column-manager-accessed')}
              isLoadingTableData={isFetching}
              numberOfRows={total}
              unfilteredNumberOfRows={unfilteredTotal}
            />
          </Space>
        </Col>

        <Col span={24}>
          <Table
            className={classes.logEntryOverviewTable}
            loading={{
              className: classes.loadingWrapper,
              ...tableIndicator,
            }}
            {...emptyState}
            columns={columnsWithPopover}
            dataSource={logEntries}
            scroll={{ x: 'max-content' }}
            tableLayout="fixed"
            onChange={onChange}
            pagination={{ ...pagination, total }}
            showWrapper={false}
            rowClassName={classes.tableRow}
            rowKey="id"
            data-cy="log-entries-overview-table"
            onRow={(record) => ({
              onClick: (event) => {
                const logRoute = routes.log.carbonFootprint.organization.edit.replace(
                  ':id',
                  String(record.log.id),
                )
                if (event.metaKey) {
                  window.open(logRoute)
                  return
                }

                navigate(logRoute)
              },
            })}
          />
        </Col>
      </Row>

      <OrganizationCarbonFootprintFiltersDrawer
        onSubmit={handleOnSubmit}
        value={filters}
        onOpenChange={() => setOpenFilterDrawer(false)}
        open={openFilterDrawer}
        visibleColumns={columnManager.visibleKeys}
      />
    </Card>
  )
}
